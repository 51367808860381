import React, { useRef } from 'react';
import { useCallback, useContext } from 'react';
import { Context } from '../providers/Modals';
const useModalWithFC = (id) => {
    const { onDismiss, onPresent } = useContext(Context);
    const ref = useRef(id || '');
    const handlePresent = useCallback((component, props, backdropClick) => {
        const el = React.createElement(component, props);
        ref.current = onPresent(el, backdropClick, id);
    }, [id, onPresent]);
    const hideModal = useCallback(() => {
        onDismiss(ref.current);
    }, [onDismiss]);
    return { showModal: handlePresent, hideModal };
};
export default useModalWithFC;
