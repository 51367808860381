import { createAction } from '@reduxjs/toolkit';
export const updateBlockNumber = createAction('app/updateBlockNumber');
export const toggleWalletModal = createAction('app/toggleWalletModal');
export const toggleSettingsMenu = createAction('app/toggleSettingsMenu');
export const addPopup = createAction('app/addPopup');
export const removePopup = createAction('app/removePopup');
export const hideInfoBox = createAction('app/hideInfoBox');
export const showInfoBox = createAction('app/showInfoBox');
export const setSlippageTolerance = createAction('app/setSlippageTolerance');
export const setUseZap = createAction('app/setUseZap');
export const refreshGlobalInfo = createAction('app/refreshGlobalInfo');
export const connectToAccount = createAction('app/connectToAccount');
export const disconnectAccount = createAction('app/disconnectAccount');
export const acceptTerms = createAction('app/acceptTerms');
