var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useComptroller } from '~src/providers/ContractProvider';
import { useMulticall } from './useMulticall';
const buildCall = (comptroller) => {
    return [
        {
            target: comptroller,
            signature: 'closeFactorMantissa() returns (uint256)',
        },
        {
            target: comptroller,
            signature: 'liquidationIncentiveMantissa() returns (uint256)',
        },
    ];
};
export const useGetComptrollerInfo = () => {
    const comptroller = useComptroller();
    const multicall = useMulticall();
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!comptroller) {
            return {};
        }
        const calls = buildCall(comptroller.address);
        const [[closeFactor], [liquidationIncentive]] = (yield multicall(calls));
        return {
            closeFactor,
            liquidationIncentive,
        };
    }), [comptroller, multicall]);
};
