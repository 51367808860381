import { parseUnits } from '@ethersproject/units';
export const max = (a, b) => (a && b ? (a.gt(b) ? a : b) : null);
export const min = (a, b) => (a && b ? (a.lt(b) ? a : b) : null);
export const safeParseUnits = (x, decimals) => {
    try {
        x = x.substr(0, x.lastIndexOf('.') + decimals + 1);
        return parseUnits(x, decimals);
    }
    catch (e) {
        return null;
    }
};
