import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import transactions from './transactions/reducer';
import markets from './markets/reducer';
import user from './user/reducer';
import comptroller from './comptroller/reducer';
import application, { initialState as appInitialState } from './application/reducer';
const PERSISTED_KEYS = [
    'application.account',
    'application.infoBoxList',
    'application.acceptedTerms',
    'transactions',
    'timelockTransactions',
    'application.connector',
];
const store = configureStore({
    reducer: {
        application,
        transactions,
        markets,
        user,
        comptroller,
    },
    middleware: [
        ...getDefaultMiddleware({
            thunk: false,
            serializableCheck: {
                ignoredPaths: ['user', 'markets', 'comptroller'],
                ignoredActions: [
                    'user/userMarketPositionFetched',
                    'market/list',
                    'user/approveErc20',
                    'user/rewardFetched',
                    'comptroller/infoFetched',
                    'user/liquidityFetched',
                ],
            },
        }),
        save({ states: PERSISTED_KEYS, namespace: '__lending' }),
    ],
    preloadedState: load({
        states: PERSISTED_KEYS,
        namespace: '__lending',
        preloadedState: {
            application: Object.assign({}, appInitialState),
        },
    }),
});
export default store;
