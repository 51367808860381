import { useWeb3React } from '@web3-react/core';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useSetConnectedAccount } from '~src/state/application/hooks';
import { isTransactionRecent, useAllTransactions, useClearAllTransactions, } from '~src/state/transactions/hooks';
import { MaxTransactionHistory } from '~src/utils/constants';
import { ButtonLink, Modal, ModalCloseButton, ModalContent, ModalHeader, } from '../Shared';
import { AddressIcon } from '../Shared/AddressIcon';
import { TransactionRow } from './TransactionRow';
export const ModalAccount = ({ onDismiss }) => {
    const { deactivate, account } = useWeb3React();
    const saveAccount = useSetConnectedAccount();
    const allTransactions = useAllTransactions();
    const clearTransaction = useClearAllTransactions();
    const isShowHistory = useMemo(() => {
        return Object.keys(allTransactions).length;
    }, [allTransactions]);
    const sortedRecentTransactions = useMemo(() => {
        const txs = Object.values(allTransactions);
        return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
    }, [allTransactions]);
    const pending = sortedRecentTransactions.filter((tx) => !tx.receipt);
    const confirmed = sortedRecentTransactions
        .filter((tx) => tx.receipt)
        .slice(0, MaxTransactionHistory);
    const disconnect = useCallback(() => {
        deactivate();
        saveAccount(undefined);
        onDismiss();
    }, [deactivate, onDismiss, saveAccount]);
    const truncatedAccount = useMemo(() => {
        return account ? account.substr(0, 20) + '...' + account.substr(-4) : '';
    }, [account]);
    return (React.createElement(Modal, null,
        React.createElement(StyledModalHeader, null,
            React.createElement(ModalCloseButton, { onClick: onDismiss }),
            React.createElement(AddressIcon, { size: "64px", address: account }),
            React.createElement("div", { className: "text-content" },
                React.createElement(Account, null, truncatedAccount),
                React.createElement(ButtonLink, { variant: "red", onClick: disconnect }, "Disconnect"))),
        React.createElement(ModalContent, null, isShowHistory ? (React.createElement(TransactionTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "History"),
                    React.createElement("th", null,
                        React.createElement(ButtonLink, { variant: "blue", onClick: clearTransaction },
                            React.createElement("i", { className: "fal fa-trash" }),
                            " Clear")))),
            React.createElement("tbody", null,
                pending.map((t) => (React.createElement(TransactionRow, Object.assign({ key: t.hash }, t)))),
                confirmed.map((t) => (React.createElement(TransactionRow, Object.assign({ key: t.hash }, t))))))) : (React.createElement(NoTransactions, null, "Your transactions will appear here")))));
};
const NoTransactions = styled.div `
  padding: 30px 0;
  text-align: center;
  color: var(--color-muted);
`;
const Account = styled.div `
  margin-bottom: 5px;
  font-weight: normal;
`;
const StyledModalHeader = styled(ModalHeader) `
  display: flex;
  align-items: center;
  .text-content {
    margin-left: 10px;
  }
`;
function newTransactionsFirst(a, b) {
    return b.addedTime - a.addedTime;
}
const TransactionTable = styled.table `
  width: 100%;
  border-spacing: 0;

  tr > th:first-child {
    width: 100%;
  }

  tr > th:last-child {
    text-align: right;
  }

  tr > td:last-child {
    text-align: right;
  }

  thead {
    th {
      text-align: left;
    }
  }

  td {
    padding: 10px 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  tbody {
    tr:not(:last-child) {
      td {
        border-bottom: 1px solid #232a3c;
      }
    }
  }
`;
