import React from 'react';
import styled from 'styled-components';
export const ErrorPopup = ({ title, message }) => {
    return (React.createElement(StyledPopupContainer, null,
        React.createElement(StyledPopupTitle, null,
            React.createElement("i", { className: "fal fa-exclamation-circle indicator" }),
            "\u00A0",
            title),
        React.createElement(StyledPopupMessage, null, message)));
};
const StyledPopupContainer = styled.div `
  border-top: 4px solid var(--color-red);
  padding: 15px;
`;
const StyledPopupTitle = styled.div `
  font-weight: 600;
  padding-bottom: 10px;
  color: var(--color-red);
`;
const StyledPopupMessage = styled.div `
  color: var(--color-white);
  font-size: 0.875rem;
`;
