var ComptrollerError;
(function (ComptrollerError) {
    ComptrollerError[ComptrollerError["NO_ERROR"] = 0] = "NO_ERROR";
    ComptrollerError[ComptrollerError["UNAUTHORIZED"] = 1] = "UNAUTHORIZED";
    ComptrollerError[ComptrollerError["COMPTROLLER_MISMATCH"] = 2] = "COMPTROLLER_MISMATCH";
    ComptrollerError[ComptrollerError["INSUFFICIENT_SHORTFALL"] = 3] = "INSUFFICIENT_SHORTFALL";
    ComptrollerError[ComptrollerError["INSUFFICIENT_LIQUIDITY"] = 4] = "INSUFFICIENT_LIQUIDITY";
    ComptrollerError[ComptrollerError["INVALID_CLOSE_FACTOR"] = 5] = "INVALID_CLOSE_FACTOR";
    ComptrollerError[ComptrollerError["INVALID_COLLATERAL_FACTOR"] = 6] = "INVALID_COLLATERAL_FACTOR";
    ComptrollerError[ComptrollerError["INVALID_LIQUIDATION_INCENTIVE"] = 7] = "INVALID_LIQUIDATION_INCENTIVE";
    ComptrollerError[ComptrollerError["MARKET_NOT_ENTERED"] = 8] = "MARKET_NOT_ENTERED";
    ComptrollerError[ComptrollerError["MARKET_NOT_LISTED"] = 9] = "MARKET_NOT_LISTED";
    ComptrollerError[ComptrollerError["MARKET_ALREADY_LISTED"] = 10] = "MARKET_ALREADY_LISTED";
    ComptrollerError[ComptrollerError["MATH_ERROR"] = 11] = "MATH_ERROR";
    ComptrollerError[ComptrollerError["NONZERO_BORROW_BALANCE"] = 12] = "NONZERO_BORROW_BALANCE";
    ComptrollerError[ComptrollerError["PRICE_ERROR"] = 13] = "PRICE_ERROR";
    ComptrollerError[ComptrollerError["REJECTION"] = 14] = "REJECTION";
    ComptrollerError[ComptrollerError["SNAPSHOT_ERROR"] = 15] = "SNAPSHOT_ERROR";
    ComptrollerError[ComptrollerError["TOO_MANY_ASSETS"] = 16] = "TOO_MANY_ASSETS";
    ComptrollerError[ComptrollerError["TOO_MUCH_REPAY"] = 17] = "TOO_MUCH_REPAY";
})(ComptrollerError || (ComptrollerError = {}));
var ComptrollerFailureInfo;
(function (ComptrollerFailureInfo) {
    ComptrollerFailureInfo[ComptrollerFailureInfo["ACCEPT_ADMIN_PENDING_ADMIN_CHECK"] = 0] = "ACCEPT_ADMIN_PENDING_ADMIN_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["ACCEPT_PENDING_IMPLEMENTATION_ADDRESS_CHECK"] = 1] = "ACCEPT_PENDING_IMPLEMENTATION_ADDRESS_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["EXIT_MARKET_BALANCE_OWED"] = 2] = "EXIT_MARKET_BALANCE_OWED";
    ComptrollerFailureInfo[ComptrollerFailureInfo["EXIT_MARKET_REJECTION"] = 3] = "EXIT_MARKET_REJECTION";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_CLOSE_FACTOR_OWNER_CHECK"] = 4] = "SET_CLOSE_FACTOR_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_CLOSE_FACTOR_VALIDATION"] = 5] = "SET_CLOSE_FACTOR_VALIDATION";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_COLLATERAL_FACTOR_OWNER_CHECK"] = 6] = "SET_COLLATERAL_FACTOR_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_COLLATERAL_FACTOR_NO_EXISTS"] = 7] = "SET_COLLATERAL_FACTOR_NO_EXISTS";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_COLLATERAL_FACTOR_VALIDATION"] = 8] = "SET_COLLATERAL_FACTOR_VALIDATION";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_COLLATERAL_FACTOR_WITHOUT_PRICE"] = 9] = "SET_COLLATERAL_FACTOR_WITHOUT_PRICE";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_IMPLEMENTATION_OWNER_CHECK"] = 10] = "SET_IMPLEMENTATION_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_LIQUIDATION_INCENTIVE_OWNER_CHECK"] = 11] = "SET_LIQUIDATION_INCENTIVE_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_LIQUIDATION_INCENTIVE_VALIDATION"] = 12] = "SET_LIQUIDATION_INCENTIVE_VALIDATION";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_MAX_ASSETS_OWNER_CHECK"] = 13] = "SET_MAX_ASSETS_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_PENDING_ADMIN_OWNER_CHECK"] = 14] = "SET_PENDING_ADMIN_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_PENDING_IMPLEMENTATION_OWNER_CHECK"] = 15] = "SET_PENDING_IMPLEMENTATION_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_PRICE_ORACLE_OWNER_CHECK"] = 16] = "SET_PRICE_ORACLE_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SUPPORT_MARKET_EXISTS"] = 17] = "SUPPORT_MARKET_EXISTS";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SUPPORT_MARKET_OWNER_CHECK"] = 18] = "SUPPORT_MARKET_OWNER_CHECK";
    ComptrollerFailureInfo[ComptrollerFailureInfo["SET_PAUSE_GUARDIAN_OWNER_CHECK"] = 19] = "SET_PAUSE_GUARDIAN_OWNER_CHECK";
})(ComptrollerFailureInfo || (ComptrollerFailureInfo = {}));
const errorMessage = {
    [ComptrollerError.UNAUTHORIZED]: 'Unauthorized',
    [ComptrollerError.COMPTROLLER_MISMATCH]: 'Comptroller mismatch',
    [ComptrollerError.INSUFFICIENT_SHORTFALL]: 'Insufficient shortfall',
    [ComptrollerError.INSUFFICIENT_LIQUIDITY]: 'Insufficient liquidity',
    [ComptrollerError.INVALID_CLOSE_FACTOR]: 'Invalid close factor',
    [ComptrollerError.INVALID_COLLATERAL_FACTOR]: 'Invalid collateral factor',
    [ComptrollerError.INVALID_LIQUIDATION_INCENTIVE]: 'Invalid liquidation incentive',
    [ComptrollerError.MARKET_NOT_ENTERED]: 'Market not entered',
    [ComptrollerError.MARKET_NOT_LISTED]: 'Market not listed',
    [ComptrollerError.MARKET_ALREADY_LISTED]: 'Market already listed',
    [ComptrollerError.MATH_ERROR]: 'Math error',
    [ComptrollerError.NONZERO_BORROW_BALANCE]: 'Nonzero borrow balance',
    [ComptrollerError.PRICE_ERROR]: 'Price error',
    [ComptrollerError.REJECTION]: 'Rejection',
    [ComptrollerError.SNAPSHOT_ERROR]: 'Snapshot error',
    [ComptrollerError.TOO_MANY_ASSETS]: 'Too many assets',
    [ComptrollerError.TOO_MUCH_REPAY]: 'Too much repay',
};
const infoMessage = {
    [ComptrollerFailureInfo.ACCEPT_ADMIN_PENDING_ADMIN_CHECK]: 'Accept admin pending admin check',
    [ComptrollerFailureInfo.ACCEPT_PENDING_IMPLEMENTATION_ADDRESS_CHECK]: 'Accept pending implementation address check',
    [ComptrollerFailureInfo.EXIT_MARKET_BALANCE_OWED]: 'Exit market balance owed',
    [ComptrollerFailureInfo.EXIT_MARKET_REJECTION]: 'Exit market rejection',
    [ComptrollerFailureInfo.SET_CLOSE_FACTOR_OWNER_CHECK]: 'Set close factor owner check',
    [ComptrollerFailureInfo.SET_CLOSE_FACTOR_VALIDATION]: 'Set close factor validation',
    [ComptrollerFailureInfo.SET_COLLATERAL_FACTOR_OWNER_CHECK]: 'Set collateral factor owner check',
    [ComptrollerFailureInfo.SET_COLLATERAL_FACTOR_NO_EXISTS]: 'Set collateral factor no exists',
    [ComptrollerFailureInfo.SET_COLLATERAL_FACTOR_VALIDATION]: 'Set collateral factor validation',
    [ComptrollerFailureInfo.SET_COLLATERAL_FACTOR_WITHOUT_PRICE]: 'Set collateral factor without price',
    [ComptrollerFailureInfo.SET_IMPLEMENTATION_OWNER_CHECK]: 'Set implementation owner check',
    [ComptrollerFailureInfo.SET_LIQUIDATION_INCENTIVE_OWNER_CHECK]: 'Set liquidation incentive owner check',
    [ComptrollerFailureInfo.SET_LIQUIDATION_INCENTIVE_VALIDATION]: 'Set liquidation incentive validation',
    [ComptrollerFailureInfo.SET_MAX_ASSETS_OWNER_CHECK]: 'Set max assets owner check',
    [ComptrollerFailureInfo.SET_PENDING_ADMIN_OWNER_CHECK]: 'Set pending admin owner check',
    [ComptrollerFailureInfo.SET_PENDING_IMPLEMENTATION_OWNER_CHECK]: 'Set pending implementation owner check',
    [ComptrollerFailureInfo.SET_PRICE_ORACLE_OWNER_CHECK]: 'Set price oracle owner check',
    [ComptrollerFailureInfo.SUPPORT_MARKET_EXISTS]: 'Support market exists',
    [ComptrollerFailureInfo.SUPPORT_MARKET_OWNER_CHECK]: 'Support market owner check',
    [ComptrollerFailureInfo.SET_PAUSE_GUARDIAN_OWNER_CHECK]: 'Set pause guardian owner check',
};
export const parseComptrollerError = (error, info) => {
    return {
        error: errorMessage[error],
        info: infoMessage[info],
    };
};
