import markets31337 from './markets.31337.json';
import markets80001 from './markets.80001.json';
import markets137 from './markets.137.json';
export const config = {
    brandName: 'Iron bank',
    network: {
        rpcUrls: {
            137: 'https://rpc-mainnet.maticvigil.com',
            80001: 'https://rpc-mumbai.matic.today',
            31337: 'http://localhost:8545',
        },
        nativeToken: {
            31337: 'ETH',
            80001: 'MATIC',
            137: 'MATIC',
        },
        explorerLink: {
            31337: '',
            137: 'https://polygonscan.com',
            56: 'https://bscscan.com',
            80001: 'https://explorer-mumbai.maticvigil.com',
        },
        defaultChainId: 137,
    },
    contracts: {
        31337: {
            comptroller: '0x712516e61C8B383dF4A63CFe83d7701Bce54B03e',
            lens: '0x05Aa229Aec102f78CE0E852A812a388F076Aa555',
            multicall: '0xed17543171C1459714cdC6519b58fFcC29A3C3c9',
        },
        80001: {
            comptroller: '0x3Bae2020Fc06bfd221cDCD53b28d3C99FE6f8187',
            lens: '0xBe8dA86eaB0cdC5387416b3C5cA280ea56d73cD3',
            multicall: '0x935Bfe9AfaA2Be26049ea4EDE40A3A2243361F87',
            reward: '0xa6DC20e3503301CE2B449418B83407beB3D610EE',
        },
        137: {
            comptroller: '0x13E8cC4e5E2fa5C91262418f30810980cA8bCeB2',
            lens: '0xf870D4801831B5662176453020cB1C11939Bed93',
            reward: '0x4855eFb2F01ba2FAFfDa7fC040F2Bc6D7ee03CD4',
            multicall: '0x2C738AABBd2FA2e7A789433965BEEb7429cB4D7e',
        },
    },
    markets: {
        31337: markets31337.markets,
        80001: markets80001.markets,
        137: markets137.markets,
    },
};
export const supportedChainIds = Object.keys(config.network.rpcUrls).map((t) => +t);
