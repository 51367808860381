export const BreakPoints = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
};
export const screenUp = (minWidth) => {
    return (style) => `@media screen and (min-width: ${BreakPoints[minWidth]}) { ${style} }`;
};
