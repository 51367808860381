import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetComptrollerInfo } from '~src/hooks/useGetComtrollerInfo';
import { comptrollerInfoFetched } from './actions';
const Updater = () => {
    const getComptrollerInfo = useGetComptrollerInfo();
    const dispatch = useDispatch();
    const { chainId } = useWeb3React();
    useEffect(() => {
        let mounted = true;
        getComptrollerInfo().then((res) => {
            if (!mounted || !chainId) {
                return;
            }
            dispatch(comptrollerInfoFetched(Object.assign({ chainId }, res)));
        });
    }, [chainId, dispatch, getComptrollerInfo]);
    return null;
};
export default Updater;
