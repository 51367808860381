import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '~src/libs/NetworkConnector';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { config, supportedChainIds } from '~src/config';
import { useSetConnectedAccount, useAddPopup } from '~src/state/application/hooks';
import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalTitle } from '../Shared';
import logoMetamask from '~src/assets/img/metamask.png';
import logoTokenPocket from '~src/assets/img/TokenPocket.svg';
import logoTrustWallet from '~src/assets/img/TrustWallet.svg';
import logoWalletConnect from '~src/assets/img/wallet_connect.png';
import { WalletConnectConnector } from '~src/libs/WalletConnectConnector';
const connectors = {
    injected: new InjectedConnector({
        supportedChainIds,
    }),
    walletconnect: new WalletConnectConnector({
        supportedChainIds,
        rpc: config.network.rpcUrls,
        bridge: 'https://bridge.walletconnect.org',
        qrcode: true,
    }),
    default: new NetworkConnector({
        urls: config.network.rpcUrls,
        defaultChainId: config.network.defaultChainId,
    }),
};
export const ModalSelectWallet = ({ onDismiss }) => {
    const isConnecting = useRef();
    const { account, activate } = useWeb3React();
    const setAccount = useSetConnectedAccount();
    const addPopup = useAddPopup();
    useEffect(() => {
        if (isConnecting.current && account) {
            setAccount(account);
            isConnecting.current = false;
        }
    }, [account, setAccount]);
    const connect = useCallback((connector) => {
        isConnecting.current = true;
        activate(connector, (e) => {
            console.log(e);
            isConnecting.current = false;
            if (e instanceof UnsupportedChainIdError) {
                addPopup({
                    error: {
                        title: 'Unsupported chain ID',
                        message: e.message,
                    },
                });
            }
        }).then(onDismiss);
    }, [activate, addPopup, onDismiss]);
    return (React.createElement(Modal, null,
        React.createElement(ModalHeader, null,
            React.createElement(ModalTitle, null, "Connect wallet"),
            React.createElement(ModalCloseButton, { onClick: onDismiss })),
        React.createElement(ModalContent, null,
            React.createElement(ButtonConnector, { onClick: () => connect(connectors.injected) },
                React.createElement(LogoContainerMultiple, null,
                    React.createElement(Logo, { src: logoTokenPocket, alt: "tokenpocked" }),
                    React.createElement(Logo, { src: logoTrustWallet, alt: "tokenpocked" }),
                    React.createElement(Logo, { style: { backgroundColor: '#fff', borderRadius: '50%' }, src: logoMetamask, alt: "metamask" })),
                React.createElement(TextContent, null,
                    React.createElement("h3", null, "Injected connector"),
                    React.createElement(Desc, { className: "text-muted" }, "Metamask or in-app browser"))),
            React.createElement(ButtonConnector, { onClick: () => connect(connectors.walletconnect) },
                React.createElement(LogoContainer, null,
                    React.createElement(Logo, { src: logoWalletConnect, alt: "wallet connect" })),
                React.createElement(TextContent, null,
                    React.createElement("h3", null, "Wallet Connect"),
                    React.createElement(Desc, { className: "text-muted" }, "Scan QR code to connect with your mobile app"))))));
};
const TextContent = styled.div `
  flex-grow: 1;
`;
const Logo = styled.img.attrs({
    width: '64px',
    height: '64px',
}) ``;
const LogoContainer = styled.div `
  position: relative;
  margin-bottom: 0;
  margin-right: 35px;
`;
const LogoContainerMultiple = styled(LogoContainer) `
  ${Logo} {
    transition: all 0.2s linear;
    &:nth-child(2) {
      position: absolute;
      left: 0;
      transform: translateX(20%);
    }

    &:nth-child(3) {
      left: 0;
      position: absolute;
      transform: translateX(40%);
    }
  }
`;
const Desc = styled.div `
  font-size: 0.8em;
`;
const ButtonConnector = styled.button `
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 15px;
  text-align: left;
  margin: 20px 0;
  border: none;
  background: var(--btn-primary-bg);
  color: var(--color-white);
  border-radius: 5px;
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    z-index: -1;
    border-radius: 5px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(270deg, #39b6f7 -3%, #730ded 100%);
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  &::after {
    content: '';
    z-index: -1;
    border-radius: 5px;
    position: absolute;
    top: 1px;
    right: 1px;
    left: 1px;
    bottom: 1px;
    background-color: var(--modal-bg);
    opacity: 1;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    ${LogoContainerMultiple} {
      ${Logo} {
        &:nth-child(2) {
          transform: translateX(60%);
        }

        &:nth-child(3) {
          transform: translateX(120%);
        }
      }
    }
  }
`;
