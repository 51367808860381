import React from 'react';
import styled from 'styled-components';
import { Modal } from '../Shared';
export const WaitingApproval = ({ message, onDismiss }) => {
    return (React.createElement(Modal, { size: "sm" },
        React.createElement(ModalHeader, null,
            React.createElement("button", { onClick: onDismiss },
                React.createElement("i", { className: "fal fa-times" }))),
        React.createElement(StyledModalContent, null,
            React.createElement(StyledLoaderContainer, null, "Loading..."),
            React.createElement(StyledTitle, null, "Waiting For Confirmation"),
            React.createElement(StyledMessage, null, message),
            React.createElement(StyledHelper, null, "Confirm this transaction in your wallet"))));
};
const ModalHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    color: var(--color-white);
    background-color: transparent;
    border: none;
    padding: 15px;
    cursor: pointer;
  }
`;
const StyledModalContent = styled.div `
  padding: 20px;
`;
const StyledLoaderContainer = styled.div `
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledTitle = styled.div `
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  color: var(--color-magenta);
`;
const StyledMessage = styled.div `
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  padding: 10px;
  line-height: 1.4;
`;
const StyledHelper = styled.div `
  font-size: 13px;
  text-align: center;
  font-weight: 400;
`;
