import React from 'react';
import styled from 'styled-components';
import { TokenSymbol } from './TokenSymbol';
export const Box = styled.div `
  overflow: hidden;
  border: var(--box-border);
  padding: 10px 15px 15px 15px;
  border-radius: 2px;
  margin-bottom: 1.875rem;
  background-color: ${(p) => (p.joint ? 'var(--box-joint-bg)' : 'var(--color-bg)')};
  border-width: ${(p) => (p.joint ? '0' : '2px')};
`;
export const BoxContentLoader = styled.div `
  padding: 5rem 0;
  text-align: center;
`;
export const BoxTitle = styled.h2 `
  margin: 0;
  padding-bottom: 0.625rem;
  font-size: 1rem;
`;
export const BoxHeading = styled.div `
  font-weight: 600;
  padding: 15px;
  display: grid;
  font-size: 0.875rem;
  color: var(--box-heading-color);
  font-weight: 600;
  grid-gap: 5px;
`;
export const BoxRow = styled.div `
  background-color: var(--box-row-bg);
  align-items: center;
  display: grid;
  padding: 15px;
  border-radius: 2px;
  position: relative;
  z-index: 0;
  grid-gap: 5px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 1px;
    right: 1px;
    left: 1px;
    bottom: 1px;
    background-color: var(--box-row-bg-hover);
    opacity: 0;
    transition: opacity 0.2s linear;
    z-index: -1;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;
export const MarketName = ({ assetSymbol, marketName, }) => {
    return (React.createElement(StyledMarketName, null,
        React.createElement(TokenSymbol, { symbol: assetSymbol, size: 48 }),
        React.createElement("div", { className: "text-content" },
            React.createElement("div", { className: "market-name" }, marketName),
            React.createElement("div", { className: "symbol" }, assetSymbol))));
};
const StyledMarketName = styled.div `
  display: flex;
  align-items: center;

  .text-content {
    margin-left: 0.625rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .symbol {
    color: var(--color-text-muted);
    font-size: 0.875rem;
    padding-top: 0.625rem;
  }

  img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .market-name {
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
