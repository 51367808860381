import { useWeb3React } from '@web3-react/core';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import useHandleTransactionReceipt from '~src/hooks/useHandleTransactionReceipt';
import { useComptroller } from '~src/providers/ContractProvider';
import { useUserReward } from '~src/state/user/hook';
import { RewardSymbol } from '~src/utils/constants';
import { BigNumberValue, Button, Modal, ModalCloseButton, ModalContent, ModalHeader, TokenSymbol, } from '../Shared';
export const ModalReward = ({ onDismiss }) => {
    const { balance, accrued } = useUserReward();
    const handleTransactionReceipt = useHandleTransactionReceipt();
    const { account } = useWeb3React();
    const comptroller = useComptroller();
    const claim = useCallback(() => {
        handleTransactionReceipt(comptroller['claimComp(address)'](account), `Claim ${RewardSymbol}`);
    }, [account, comptroller, handleTransactionReceipt]);
    return (React.createElement(Modal, { size: "sm" },
        React.createElement(StyledModalHeader, null,
            React.createElement(ModalCloseButton, { onClick: onDismiss }),
            React.createElement(TokenSymbol, { symbol: RewardSymbol, size: 80 })),
        React.createElement(ModalContent, null,
            React.createElement(Grid, null,
                React.createElement(Label, null, "Wallet balance"),
                React.createElement(Value, null,
                    React.createElement(BigNumberValue, { value: balance, decimals: 18 })),
                React.createElement(Label, null, "Accrued"),
                React.createElement(Value, null,
                    React.createElement(BigNumberValue, { value: accrued, decimals: 18 })),
                React.createElement(Label, { noBorder: true }, "Price"),
                React.createElement(Value, { noBorder: true }, "TBU")),
            React.createElement(ModalActions, null,
                React.createElement(Button, { variant: "primary", onClick: claim, block: true }, "Claim")))));
};
const Label = styled.div `
  font-size: 0.8em;
  padding: 0.625rem 0;
  border-bottom: ${(p) => (!p.noBorder ? '1px solid #232a3c' : '')};
`;
const Value = styled.div `
  text-align: right;
  padding: 0.625rem 0;
  color: var(--color-white-light);
  border-bottom: ${(p) => (!p.noBorder ? '1px solid #232a3c' : '')};
`;
const Grid = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 0.625rem;
  align-items: end;
`;
const ModalActions = styled.div `
  text-align: center;
`;
const StyledModalHeader = styled(ModalHeader) `
  display: flex;
  justify-content: center;
  background: transparent;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background: var(--modal-header-gradient);
    z-index: 0;
  }
`;
