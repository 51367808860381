import React, { createContext, useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
const TabContext = createContext(null);
export const NavTab = ({ active, onChange, children, justify }) => {
    const value = useMemo(() => {
        return {
            active,
            onChange,
        };
    }, [active, onChange]);
    return (React.createElement(TabContext.Provider, { value: value },
        React.createElement(StyledNavTab, { justify: justify }, children)));
};
export const NavTabItem = ({ id, children }) => {
    const { active, onChange } = useContext(TabContext);
    const onClick = useCallback(() => {
        onChange(id);
    }, [id, onChange]);
    return (React.createElement(StyledNavTabItem, { active: active === id, onClick: onClick }, children));
};
const StyledNavTabItem = styled.li `
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  transition: all 0.2s linear;

  &:after {
    content: '';
    left: 30%;
    right: 30%;
    top: 100%;
    height: 3px;
    position: absolute;
    transition: all 0.2s linear;
    background: ${(p) => (p.active ? 'var(--color-nav-active)' : 'transparent')};
  }

  &:hover {
    color: var(--color-nav-active);
  }
`;
export const StyledNavTab = styled.ul `
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: nowrap;
  /* justify-content: stretch; */

  ${StyledNavTabItem} {
    flex-grow: ${(p) => (p.justify ? 1 : undefined)};
  }
`;
