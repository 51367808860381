import { Zero } from '@ethersproject/constants';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Precision } from '~src/utils/constants';
import { useAllMarkets } from '../markets/hooks';
export const useUser = () => {
    const { chainId } = useWeb3React();
    return useSelector((x) => {
        return x.user[chainId];
    });
};
export const useTokenBalance = (token) => {
    const user = useUser();
    return user ? user.walletBalances[token] : null;
};
export const useUserPosition = (market) => {
    const user = useUser();
    return user
        ? {
            supplying: user.supplying ? user.supplying[market] : null,
            borrowing: user.borrowing ? user.borrowing[market] : null,
        }
        : {};
};
export const useIsEnteredMarket = (market) => {
    var _a;
    const user = useUser();
    return user && ((_a = user.enteredMarkets) === null || _a === void 0 ? void 0 : _a.includes(market));
};
export const useIsTokenEnabled = (market) => {
    const user = useUser();
    return user && user.allowance[market] && user.allowance[market].gt(0);
};
export const useUserBalance = () => {
    const user = useUser();
    const markets = useAllMarkets();
    const totalSupply = useMemo(() => {
        if (!markets || !user) {
            return null;
        }
        return markets
            .map((t) => {
            var _a;
            if (user.supplying[t.marketAddress] && ((_a = user.enteredMarkets) === null || _a === void 0 ? void 0 : _a.includes(t.marketAddress))) {
                return user.supplying[t.marketAddress]
                    .mul(t.exchangeRate)
                    .mul(t.underlyingPrice)
                    .div(Precision)
                    .div(Precision);
            }
            return Zero;
        })
            .reduce((a, b) => a.add(b), Zero);
    }, [markets, user]);
    const borrowLimit = useMemo(() => {
        if (!markets || !user) {
            return null;
        }
        return markets
            .map((t) => {
            var _a;
            if (user.supplying[t.marketAddress] && ((_a = user.enteredMarkets) === null || _a === void 0 ? void 0 : _a.includes(t.marketAddress))) {
                return user.supplying[t.marketAddress]
                    .mul(t.exchangeRate)
                    .mul(t.underlyingPrice)
                    .mul(t.collateralFactor)
                    .div(Precision)
                    .div(Precision)
                    .div(Precision);
            }
            return Zero;
        })
            .reduce((a, b) => a.add(b), Zero);
    }, [markets, user]);
    const borrowBalance = useMemo(() => {
        if (!markets || !user) {
            return null;
        }
        return markets
            .map((t) => {
            if (user.borrowing[t.marketAddress]) {
                return user.borrowing[t.marketAddress].mul(t.underlyingPrice).div(Precision);
            }
            return Zero;
        })
            .reduce((a, b) => a.add(b), Zero);
    }, [markets, user]);
    const borrowLimitPercentage = useMemo(() => {
        return borrowBalance && borrowLimit && borrowLimit.gt(0)
            ? (borrowBalance === null || borrowBalance === void 0 ? void 0 : borrowBalance.mul(1e6).div(borrowLimit).toNumber()) / 1e4
            : 0;
    }, [borrowBalance, borrowLimit]);
    return user
        ? {
            totalSupply,
            borrowBalance,
            borrowLimit,
            borrowLimitPercentage,
        }
        : {};
};
export const useUserReward = () => {
    const user = useUser();
    return user
        ? {
            balance: user.rewardBalance,
            accrued: user.rewardAccrued,
        }
        : {};
};
