import React from 'react';
import styled from 'styled-components';
import { useActivePopups } from '../../state/application/hooks';
import { PopupItem } from './PopupItem';
const MobilePopupWrapper = styled.div `
  position: relative;
  max-width: 100%;
  display: none;
  height: ${({ height }) => height};
  margin: ${({ height }) => (height ? '0 auto;' : 0)};
  margin-bottom: ${({ height }) => (height ? '20px' : 0)};
  @media (max-width: 768px) {
    display: block;
    margin-bottom: ${({ height }) => (height ? '10px' : 0)};
  } ;
`;
const MobilePopupInner = styled.div `
  height: 99%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const FixedPopupColumn = styled.div `
  position: fixed;
  top: 80px;
  margin-right: 0px;
  right: 1rem;
  max-width: 355px !important;
  width: 100%;
  z-index: 102;

  @media (max-width: 768px) {
    display: none;
  } ;
`;
export const Popups = () => {
    const activePopups = useActivePopups();
    return (React.createElement(React.Fragment, null,
        React.createElement(FixedPopupColumn, null, activePopups.map((item) => (React.createElement(PopupItem, { key: item.key, content: item.content, popKey: item.key, removeAfterMs: item.removeAfterMs })))),
        React.createElement(MobilePopupWrapper, { height: (activePopups === null || activePopups === void 0 ? void 0 : activePopups.length) > 0 ? 'fit-content' : 0 },
            React.createElement(MobilePopupInner, null, activePopups // reverse so new items up front
                .slice(0)
                .reverse()
                .map((item) => (React.createElement(PopupItem, { key: item.key, content: item.content, popKey: item.key, removeAfterMs: item.removeAfterMs })))))));
};
