var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FunctionFragment, Interface } from '@ethersproject/abi';
const MulticallAbi = [
    {
        inputs: [
            {
                components: [
                    { internalType: 'address', name: 'target', type: 'address' },
                    { internalType: 'bytes', name: 'callData', type: 'bytes' },
                ],
                internalType: 'struct Multicall.Call[]',
                name: 'calls',
                type: 'tuple[]',
            },
        ],
        name: 'aggregate',
        outputs: [
            { internalType: 'uint256', name: 'blockNumber', type: 'uint256' },
            { internalType: 'bytes[]', name: 'returnData', type: 'bytes[]' },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'uint256', name: 'blockNumber', type: 'uint256' }],
        name: 'getBlockHash',
        outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCurrentBlockCoinbase',
        outputs: [{ internalType: 'address', name: 'coinbase', type: 'address' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCurrentBlockDifficulty',
        outputs: [{ internalType: 'uint256', name: 'difficulty', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCurrentBlockGasLimit',
        outputs: [{ internalType: 'uint256', name: 'gaslimit', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getCurrentBlockTimestamp',
        outputs: [{ internalType: 'uint256', name: 'timestamp', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [{ internalType: 'address', name: 'addr', type: 'address' }],
        name: 'getEthBalance',
        outputs: [{ internalType: 'uint256', name: 'balance', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [],
        name: 'getLastBlockHash',
        outputs: [{ internalType: 'bytes32', name: 'blockHash', type: 'bytes32' }],
        stateMutability: 'view',
        type: 'function',
    },
];
const encodeCallData = (call) => {
    const iface = call.contract
        ? call.contract.interface
        : new Interface([`function ${call.signature}`]);
    const method = call.method || FunctionFragment.fromString(call.signature).name;
    const callData = iface.encodeFunctionData(method, call.params || []);
    return callData;
};
const decodeReturnData = (call, data) => {
    var _a;
    const iface = call.contract
        ? call.contract.interface
        : new Interface([`function ${call.signature}`]);
    const method = call.method || FunctionFragment.fromString(call.signature).name;
    try {
        const result = iface.decodeFunctionResult(method, data);
        return result;
    }
    catch (e) {
        console.warn('Can not decode result of call', {
            address: call.target || ((_a = call.contract) === null || _a === void 0 ? void 0 : _a.address),
            method: call.signature || call.method,
        });
        return [];
    }
};
const multicallInterface = new Interface(MulticallAbi);
export const multicall = (provider, multicallAddress, calls) => __awaiter(void 0, void 0, void 0, function* () {
    if (!calls || !calls.length) {
        return [];
    }
    const callData = calls.map((call) => {
        var _a;
        return [call.target || ((_a = call.contract) === null || _a === void 0 ? void 0 : _a.address), encodeCallData(call)];
    });
    const aggregateData = multicallInterface.encodeFunctionData('aggregate', [callData]);
    const response = yield provider.send('eth_call', [
        {
            to: multicallAddress,
            data: aggregateData,
        },
        'latest',
    ]);
    const { returnData } = multicallInterface.decodeFunctionResult('aggregate', response);
    return calls.map((call, index) => {
        return decodeReturnData(call, returnData[index]);
    });
});
