import { formatUnits } from '@ethersproject/units';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { safeParseUnits } from '~src/utils/numbers';
import { TokenSymbol } from './TokenSymbol';
import { ValueRange } from './ValueRange';
export const TokenInput = ({ maxValue, decimals, steps, maxRate, symbol, maxRateLabel, value, onChange, disabled, }) => {
    const [focus, setFocus] = useState(false);
    const inputRef = useRef();
    const [ratio, setRatio] = useState(0);
    const onFocus = useCallback(() => {
        setFocus(true);
    }, []);
    const onBlur = useCallback(() => {
        setFocus(false);
    }, []);
    const inputValue = useMemo(() => {
        if (!value) {
            return '';
        }
        const inputValue = inputRef.current.value;
        if (inputValue && value.eq(safeParseUnits(inputValue, decimals))) {
            return inputValue;
        }
        return formatUnits(value, decimals);
    }, [decimals, value]);
    useEffect(() => {
        if (!value || !maxValue || maxValue.eq(0)) {
            setRatio(0);
            return;
        }
        setRatio(value.mul(100).div(maxValue).toNumber());
    }, [maxValue, value]);
    const setValueFromRatio = useCallback((ratio) => {
        if (maxValue && onChange) {
            const value = maxValue.mul(ratio).div(100);
            onChange(value);
        }
    }, [maxValue, onChange]);
    const onInputChange = useCallback((ev) => {
        if (onChange) {
            const _v = ev.target.value ? safeParseUnits(ev.target.value, decimals) : null;
            onChange(_v);
        }
    }, [decimals, onChange]);
    const isValid = useMemo(() => {
        return !maxValue || maxValue.eq(0) || !value || value.eq(0) || value.lte(maxValue);
    }, [maxValue, value]);
    return (React.createElement(React.Fragment, null,
        React.createElement(TokenInputContainer, { focused: focus, isValid: isValid },
            React.createElement("input", { placeholder: "0.0", ref: inputRef, onFocus: onFocus, onBlur: onBlur, type: "text", autoFocus: true, value: inputValue, onChange: onInputChange, disabled: disabled }),
            React.createElement("div", { className: "symbol" },
                React.createElement(TokenSymbol, { symbol: symbol, size: 30 }))),
        (steps === null || steps === void 0 ? void 0 : steps.length) ? (React.createElement(ValueRange, { steps: steps, value: ratio, onChange: setValueFromRatio, max: maxRate, maxLabel: maxRateLabel })) : null));
};
// export for styling
export const TokenInputContainer = styled.div `
  padding: 10px 10px;
  position: relative;
  border-radius: 10px;
  border: var(--token-input-border);
  background-color: var(--token-input-bg);
  box-shadow: ${(p) => (p.isValid ? '' : 'var(--token-input-shadow-invalid)')};

  input {
    width: 100%;
    display: block;
    padding: 6px;
    padding-right: 50px;
    border: none;
    background-color: transparent;
    font-size: 1.5em;
    color: ${(p) => p.isValid ? 'var(--token-input-color)' : 'var(--token-input-color-invalid)'};
    outline: none;
  }

  .symbol {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
