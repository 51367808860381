import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
export const useAllMarkets = () => {
    const { chainId } = useWeb3React();
    return useSelector((x) => x.markets[chainId] || []);
};
export const useMarket = (asset) => {
    const markets = useAllMarkets();
    return useMemo(() => {
        return markets.find((x) => x.asset === asset);
    }, [asset, markets]);
};
