var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MaxUint256 } from '@ethersproject/constants';
import { useWeb3React } from '@web3-react/core';
import { flatten } from 'lodash';
import { useCallback, useMemo } from 'react';
import { config } from '../config';
import { useMulticall } from './useMulticall';
import { useNativeTokenSymbol } from './useNativeTokenSymbol';
const buildCall = (market, underlying, account, nativeToken) => {
    const calls = [
        {
            target: market,
            signature: 'balanceOf(address) returns (uint)',
            params: [account],
        },
        {
            target: market,
            signature: 'borrowBalanceStored(address) returns (uint)',
            params: [account],
        },
    ];
    if (underlying === nativeToken) {
        return calls;
    }
    return [
        ...calls,
        {
            target: underlying,
            signature: 'balanceOf(address) returns (uint)',
            params: [account],
        },
        {
            target: underlying,
            signature: 'allowance(address, address) returns (uint)',
            params: [account, market],
        },
    ];
};
export const useGetUserPositionInMarket = () => {
    const { chainId, library } = useWeb3React();
    const multicall = useMulticall();
    const nativeToken = useNativeTokenSymbol();
    const allMarkets = useMemo(() => {
        return config.markets[chainId];
    }, [chainId]);
    const getNativeBalance = useCallback((account) => __awaiter(void 0, void 0, void 0, function* () {
        return yield library.getBalance(account);
    }), [library]);
    return useCallback((account) => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return {};
        }
        const calls = flatten(allMarkets.map((t) => buildCall(t.marketAddress, t.assetAddress, account, nativeToken)));
        const [response, nativeBalance] = yield Promise.all([
            multicall(calls),
            getNativeBalance(account),
        ]);
        let counter = 0;
        const data = allMarkets.map((item) => {
            const chunkSize = item.asset === nativeToken ? 2 : 4;
            const data = response.slice(counter, counter + chunkSize);
            counter += chunkSize;
            return {
                marketAddress: item.marketAddress,
                underlying: item.assetAddress,
                underlyingSymbol: item.asset,
                xTokenBalance: data[0][0],
                borrowBalance: data[1][0],
                walletBalance: item.asset === nativeToken ? nativeBalance : data[2][0],
                allowance: item.asset === nativeToken ? MaxUint256 : data[3][0],
            };
        });
        return {
            chainId,
            data,
        };
    }), [allMarkets, chainId, getNativeBalance, multicall, nativeToken]);
};
