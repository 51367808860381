import styled from 'styled-components';
export const Button = styled.button `
  display: ${(p) => (p.block ? 'block' : '')};
  width: ${(p) => (p.block ? '100%' : '')};
  padding: ${(p) => (p.large ? '15px 20px' : '10px 25px')};
  text-transform: ${(p) => (p.large ? 'uppercase' : '')};
  font-weight: ${(p) => (p.large ? 'bold' : '')};
  border-radius: 1000px;
  border: none;
  position: relative;
  background: transparent;
  z-index: 0;

  &::after {
    border-radius: 1000px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity.3s ease-in-out;
  }

  &:after {
    opacity: 0;
    z-index: -1;
  }

  &:not(:disabled) {
    color: var(--btn-${(p) => p.variant}-color);
    font-weight: 600;
    cursor: pointer;
    background: var(--btn-${(p) => p.variant}-bg);

    &::after {
      background: var(--btn-${(p) => p.variant}-bg-hover);
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  &:disabled {
    background: var(--color-text-muted);
    color: var(--color-gray-1);
  }
`;
export const ButtonLink = styled.button `
  border: none;
  background-color: transparent !important;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  font-weight: normal;

  &:not(:disabled) {
    color: var(--color-${(p) => p.variant});
    cursor: pointer;

    &:hover {
      color: var(--color-${(p) => p.variant}-light);
    }
  }
`;
