import { Contract } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import React, { createContext, useContext, useEffect, useMemo, useState, } from 'react';
import { config } from '../config';
import Comptroller from '../abis/Comptroller.json';
import CompoundLens from '../abis/CompoundLens.json';
const Context = createContext(null);
export const ContractProvider = ({ children }) => {
    const { account, library, chainId } = useWeb3React();
    const [comptroller, setComptroller] = useState();
    const [lens, setLens] = useState();
    const provider = useMemo(() => {
        return account ? library.getSigner(account) : library;
    }, [library, account]);
    useEffect(() => {
        const contractAddresses = config.contracts[chainId];
        if (contractAddresses) {
            setComptroller(new Contract(contractAddresses.comptroller, Comptroller.abi, provider));
            setLens(new Contract(contractAddresses.lens, CompoundLens.abi, provider));
        }
        else {
            setComptroller(null);
            setLens(null);
        }
    }, [chainId, provider]);
    const contextValue = useMemo(() => {
        return {
            comptroller,
            lens,
        };
    }, [comptroller, lens]);
    return React.createElement(Context.Provider, { value: contextValue }, children);
};
export const useComptroller = () => {
    const context = useContext(Context);
    return context === null || context === void 0 ? void 0 : context.comptroller;
};
export const useLens = () => {
    const context = useContext(Context);
    return context === null || context === void 0 ? void 0 : context.lens;
};
