import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '~src/libs/NetworkConnector';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGetConnectedAccount } from '../../state/application/hooks';
import { config, supportedChainIds } from '~/src/config';
import logo from '../../assets/img/ironbank_logo.svg';
import { usePendingTransactionCount } from '../../state/transactions/hooks';
import { BigNumberValue, Container, TokenSymbol } from '../Shared';
import useModal from '~src/hooks/useModal';
import { ModalAccount, ModalSelectWallet } from '../ModalAccount';
import { ModalReward } from '../ModalAccount/ModalReward';
import { useUserReward } from '~src/state/user/hook';
import { NavLink } from 'react-router-dom';
import { AddressIcon } from '../Shared/AddressIcon';
import { RewardSymbol } from '~src/utils/constants';
import { screenUp } from '~src/utils/styles';
import { useBodyNoScroll } from '~src/hooks/useBodyNoScroll';
const connectors = {
    injected: new InjectedConnector({
        supportedChainIds,
    }),
    default: new NetworkConnector({
        urls: config.network.rpcUrls,
        defaultChainId: config.network.defaultChainId,
    }),
};
export const Header = () => {
    const { account, activate } = useWeb3React();
    const savedAccount = useGetConnectedAccount();
    const [connect] = useModal(React.createElement(ModalSelectWallet, null));
    const [showModalAccount] = useModal(React.createElement(ModalAccount, null));
    const [showModalReward] = useModal(React.createElement(ModalReward, null));
    const { accrued } = useUserReward();
    const pendingTransactionCount = usePendingTransactionCount();
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = useCallback(() => {
        setShowMenu((x) => !x);
    }, []);
    const hideMenu = useCallback(() => {
        setShowMenu(false);
    }, []);
    useBodyNoScroll(showMenu);
    useEffect(() => {
        // auto activate connector
        if (!savedAccount && !account) {
            activate(connectors.default, (e) => {
                console.error(e);
            });
            return;
        }
        // TODO: check if saved connector is injected
        if (savedAccount && !account) {
            activate(connectors.injected, () => {
                return activate(connectors.default);
            }).catch((e) => {
                console.error(e);
            });
        }
    }, [account, activate, savedAccount]);
    return (React.createElement(StyledHeader, null,
        React.createElement(Container, null,
            React.createElement(ButtonToggleMenu, { onClick: toggleMenu, isClose: showMenu },
                React.createElement("span", null)),
            React.createElement("div", { className: "logo" },
                React.createElement("img", { src: logo, alt: "Ironbank", width: "157px", height: "46px" })),
            React.createElement(NavBar, { show: showMenu, onClick: hideMenu },
                React.createElement("ul", null,
                    React.createElement(NavItem, null,
                        React.createElement(NavLink, { activeClassName: "active", to: "/", exact: true }, "Dashboard")),
                    React.createElement(NavItem, null,
                        React.createElement(NavLink, { activeClassName: "active", to: "/markets" }, "Markets")),
                    React.createElement(NavItem, null,
                        React.createElement(NavLink, { activeClassName: "active", to: "/liquidation" }, "Liquidation"))),
                account ? (React.createElement(ButtonClaim, { onClick: showModalReward },
                    React.createElement("div", { className: "text" },
                        React.createElement(BigNumberValue, { value: accrued, decimals: 18, fractionDigits: 4 })),
                    React.createElement(TokenSymbol, { symbol: RewardSymbol, size: 24 }))) : null),
            account ? (React.createElement(ButtonAccount, { onClick: showModalAccount },
                pendingTransactionCount ? (React.createElement(React.Fragment, null,
                    React.createElement("i", { className: "fas fa-circle-notch fa-spin text-cyan" }),
                    React.createElement("em", { className: "text-cyan pending" }, pendingTransactionCount),
                    ' ')) : null,
                React.createElement(AddressIcon, { size: "24px", address: account }),
                React.createElement("span", { className: "account-number d-none d-lg-inline" },
                    "\u00A0",
                    account))) : (React.createElement(ButtonConnect, { onClick: connect },
                React.createElement("i", { className: "fal fa-plug" }),
                React.createElement("span", { className: "d-none d-lg-inline" }, "\u00A0Connect"))))));
};
const ButtonToggleMenu = styled.button `
  width: 48px;
  height: 48px;
  border: none;
  background: transparent;
  position: relative;
  z-index: 99;

  &::before {
    content: '';
    position: absolute;
    height: 3px;
    left: 5px;
    right: 15px;
    background: var(--color-text-muted);
    opacity: ${(p) => (p.isClose ? 0 : 1)};
    transition: opacity 0.2s linear;
  }

  span {
    position: absolute;
    height: 3px;
    left: 5px;
    right: 15px;
    background: transparent;

    &::after,
    &::before {
      background: var(--color-text-muted);
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      transition: transform 0.2s linear;
      transform-origin: center;
    }

    &::before {
      transform: ${(p) => p.isClose ? 'rotate(45deg) translateY(0)' : 'translateY(-0.625rem)'};
    }

    &::after {
      transform: ${(p) => p.isClose ? 'rotate(315deg) translateY(0)' : 'translateY(0.625rem)'};
    }
  }

  ${screenUp('lg') `
    display: none;
  `}
`;
const StyledHeader = styled.header `
  background-color: var(--color-header-bg);
  grid-area: header;
  position: relative;

  ${Container} {
    display: flex;
    height: 5rem;
    align-items: center;
  }

  .logo {
    letter-spacing: 2px;
    font-size: 1.2rem;
    color: var(--color-magenta);
    display: flex;
    align-items: center;
    position: relative;
    z-index: 99;
    img {
      vertical-align: middle;
      padding-right: 0.625rem;
    }
  }
`;
const ButtonOutline = styled.button `
  background-color: var(--color-header-bg);
  border-radius: 10000px;
  position: relative;
  border: none;
  color: #fff;
  z-index: 0;
  background-image: linear-gradient(91deg, #39b6f7 -3%, #730ded 100%);
  text-align: center;
  font-weight: normal;
  vertical-align: middle;
  white-space: nowrap;
  flex-shrink: 0;

  &::after {
    content: '';
    z-index: -1;
    border-radius: 10000px;
    position: absolute;
    top: 1px;
    right: 1px;
    left: 1px;
    bottom: 1px;
    background-color: var(--color-header-bg);
    transition: opacity 0.2s linear;
    opacity: 1;
  }

  &:hover {
    &:after {
      opacity: 0;
    }
  }
`;
const ButtonClaim = styled(ButtonOutline) `
  padding: 7px 0.625rem;
  white-space: nowrap;
  .text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
`;
const ButtonAccount = styled(ButtonOutline) `
  padding: 7px;
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  .pending {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
    font-style: normal;
    font-weight: 600;
  }

  .account-number {
    max-width: 6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    font-weight: 400;
  }
`;
const ButtonConnect = styled(ButtonOutline) `
  margin-left: auto;
  padding: 7px;
  line-height: 24px;

  i {
    display: inline-block;
    width: 24px;
  }

  ${screenUp('lg') `
    padding: 7px 25px 7px 15px;
  `}
`;
const NavItem = styled.li `
  a {
    display: block;
    width: 100%;
    position: relative;
    text-decoration: none;
    transition: opacity 0.15s linear;
    color: var(--color-text-muted);
    font-weight: 600;
    padding: 15px 20px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-image: linear-gradient(to bottom, #ff7629 -3%, #8f1beb 100%);
      transition: all 0.15s linear;
      width: 3px;
      height: 100%;
      left: 0;
      top: 0;
      transform: scaleY(0);
      transform-origin: bottom;
    }

    &.active {
      color: var(--color-text);
      background: #121212;
      &:after {
        transform: scaleY(1);
      }
    }

    &:not(.active):hover {
      color: var(--color-text);
      &:after {
        transform: scaleY(1);
      }
    }
  }

  ${screenUp('lg') `
    display: inline-block;
    
    a {
      padding: 1.5rem 0.625rem;
      line-height: 32px;
      display: inline-block;
      background: transparent;
      
      &::after {
        width: 2em;
        height: 3px;
        left: calc(50% - 1em);
        bottom: 0;
        top: unset;
        background-image: linear-gradient(to right, #ff7629 -3%, #8f1beb 100%);
      }

      &.active {
        background: transparent;
      }
    }
  `}
`;
const NavBar = styled.div `
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background: var(--color-header-bg);
  margin: 0;
  z-index: 98;
  transform: ${(p) => (p.show ? 'translate(0)' : 'translateX(-100%)')};
  transition: transform 0.2s linear;
  padding: 5rem 0rem 1.875rem 0rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ${screenUp('lg') `
    margin: 0 0.625rem 0 1.875rem;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    transform: none !important;

    ul {
      flex-grow: 1;
    }
  `}

  ${ButtonClaim} {
    margin: 15px;
  }
`;
