import React from 'react';
import ApplicationUpdater from './application/updater';
import TransactionUpdater from './transactions/updater';
import MarketsUpdater from './markets/updater';
import UserUpdater from './user/updater';
import ComptrollerUpdater from './comptroller/updater';
const Updaters = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ComptrollerUpdater, null),
        React.createElement(ApplicationUpdater, null),
        React.createElement(TransactionUpdater, null),
        React.createElement(MarketsUpdater, null),
        React.createElement(UserUpdater, null)));
};
export default Updaters;
