import { useWeb3React } from '@web3-react/core';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTransaction, clearAllTransactions } from './actions';
// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder() {
    const { chainId, account } = useWeb3React();
    const dispatch = useDispatch();
    return useCallback((response, { summary, approval, redemption } = {}) => {
        // if (!account) return;
        if (!chainId)
            return;
        const { hash } = response;
        if (!hash) {
            throw Error('No transaction hash found.');
        }
        dispatch(addTransaction({ hash, from: account, chainId, approval, summary, redemption }));
    }, [dispatch, chainId, account]);
}
// returns all the transactions for the current chain
export function useAllTransactions() {
    var _a;
    const { chainId } = useWeb3React();
    const state = useSelector((state) => state.transactions);
    return chainId ? (_a = state[chainId]) !== null && _a !== void 0 ? _a : {} : {};
}
export function useIsTransactionPending(transactionHash) {
    const transactions = useAllTransactions();
    if (!transactionHash || !transactions[transactionHash]) {
        return false;
    }
    return !transactions[transactionHash].receipt;
}
/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx) {
    return new Date().getTime() - tx.addedTime < 86400000;
}
// returns whether a token has a pending approval transaction
export function useHasPendingApproval(tokenAddress, spender) {
    const allTransactions = useAllTransactions();
    return useMemo(() => typeof tokenAddress === 'string' &&
        typeof spender === 'string' &&
        Object.keys(allTransactions).some((hash) => {
            const tx = allTransactions[hash];
            if (!tx)
                return false;
            if (tx.receipt) {
                return false;
            }
            else {
                const approval = tx.approval;
                if (!approval)
                    return false;
                return (approval.spender === spender &&
                    approval.tokenAddress === tokenAddress &&
                    isTransactionRecent(tx));
            }
        }), [allTransactions, spender, tokenAddress]);
}
export function useHasPendingRedemption(poolAddress) {
    const allTransactions = useAllTransactions();
    return useMemo(() => typeof poolAddress === 'string' &&
        Object.keys(allTransactions).some((hash) => {
            const tx = allTransactions[hash];
            if (!tx || tx.receipt || !isTransactionRecent(tx)) {
                return false;
            }
            const { redemption } = tx;
            if (!redemption) {
                return false;
            }
            return redemption.poolAddress === poolAddress;
        }), [allTransactions, poolAddress]);
}
export function useClearAllTransactions() {
    const { chainId } = useWeb3React();
    const dispatch = useDispatch();
    return useCallback(() => dispatch(clearAllTransactions({ chainId })), [chainId, dispatch]);
}
export const usePendingTransactionCount = () => {
    const allTransactions = useAllTransactions();
    return useMemo(() => Object.values(allTransactions).filter((tx) => !tx.receipt).length, [allTransactions]);
};
