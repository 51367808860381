import { BigNumber } from '@ethersproject/bignumber';
import { parseUnits } from '@ethersproject/units';
export const BlocksPerYear = 15768000;
export const Precision = parseUnits('1', 18);
export const MaxTransactionHistory = 10;
export const Zero = BigNumber.from(0);
export const DefaultTokenInputSteps = [
    { rate: 0 },
    { rate: 25 },
    { rate: 50 },
    { rate: 75 },
    { rate: 100 },
];
export const RewardSymbol = 'VIB';
