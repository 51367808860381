import React from 'react';
import styled from 'styled-components';
import { screenUp } from '~src/utils/styles';
export const Modal = ({ children, size, padding, background }) => {
    return (React.createElement(Container, { size: size || 'md' },
        React.createElement(StyledModal, null,
            React.createElement(Card, { width: "auto", padding: padding, background: background },
                React.createElement(CardContent, null, children)))));
};
const Container = styled.div `
  max-width: var(--modal-size-${(p) => p.size});
  width: 100%;
  margin: 50px auto;
  position: relative;
  z-index: 102;

  ${screenUp('lg') `
    margin-top: 100px;
  `}
`;
const StyledModal = styled.div `
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.8);
  background-color: var(--modal-bg);
  overflow: hidden;
`;
const Card = styled.div ``;
const CardContent = styled.div `
  padding: 0px;
`;
export const ModalContent = styled.div `
  padding: 10px 25px 20px 25px;
  position: relative;
`;
export const ModalHeader = styled.div `
  padding: 15px 24px;
  position: relative;
  background: var(--modal-header-gradient);
`;
export const ModalTitle = styled.div `
  font-size: 1.4rem;
  color: var(--color-cyan);
  font-weight: 600;
  text-align: center;
`;
export const ModalCloseButton = styled.button.attrs({
    children: React.createElement("i", { className: "fal fa-times" }),
}) `
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: var(--modal-close-color);
  transition: color 0.2s ease-in-out;

  &:hover {
    color: var(--modal-close-color-hover);
  }
`;
export const ModalFooter = styled.div `
  padding: 10px 25px 10px 25px;
`;
export const ModalContentLoading = () => {
    return (React.createElement(StyledModalContentLoading, null,
        React.createElement("i", { className: "fal fa-spin fa-circle-notch fa-3x text-blue" })));
};
const StyledModalContentLoading = styled.div `
  position: absolute;
  background-color: var(--color-black);
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 1.2rem;
`;
