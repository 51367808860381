import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetMarketsMetadata } from '../../hooks/useGetMarketsMetadata';
import { usePendingTransactionCount } from '../transactions/hooks';
import { listMarket } from './actions';
const Updater = () => {
    const getMarketMetadata = useGetMarketsMetadata();
    const dispatch = useDispatch();
    const { account } = useWeb3React();
    const pendingTransactionCount = usePendingTransactionCount();
    useEffect(() => {
        let mounted = true;
        getMarketMetadata().then((res) => {
            if (mounted && res.chainId) {
                dispatch(listMarket({
                    chainId: res.chainId,
                    markets: res.metadata,
                }));
            }
        });
        return () => {
            mounted = false;
        };
    }, [dispatch, getMarketMetadata, account, pendingTransactionCount]);
    return null;
};
export default Updater;
