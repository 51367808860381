/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { nanoid } from '@reduxjs/toolkit';
import React, { createContext, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useBodyClass } from '~src/hooks/useBodyNoScroll';
export const Context = createContext({
    onPresent: () => '',
    onDismiss: () => { },
});
const modalSetter = (info) => (state) => {
    const existed = info.id && state.some((x) => x.id === info.id);
    if (!existed) {
        return [...state, info];
    }
    return state.map((item) => (item.id !== info.id ? item : info));
};
const Modals = ({ children }) => {
    const [modals, setModals] = useState([]);
    useBodyClass(!!(modals === null || modals === void 0 ? void 0 : modals.length), 'modal-open');
    const wrapper = useRef();
    const handlePresent = useCallback((modalContent, backdropClick, id) => {
        id = id || nanoid();
        setModals(modalSetter({ id, content: modalContent, backdropClick }));
        return id;
    }, []);
    const handleDismiss = useCallback((id) => {
        setModals((data) => data.filter((t) => t.id !== id));
    }, []);
    const backdropClick = useCallback((ev) => {
        ev.stopPropagation();
        if (modals.length === 0) {
            return;
        }
        setModals((data) => data.slice(0, data.length - 1));
    }, [modals]);
    return (React.createElement(Context.Provider, { value: {
            content: modals,
            onPresent: handlePresent,
            onDismiss: handleDismiss,
        } },
        children, modals === null || modals === void 0 ? void 0 :
        modals.map((modal) => (React.createElement(StyledModalWrapper, { ref: wrapper, key: modal.id },
            React.createElement(StyledBackdrop, { onClick: modal.backdropClick ? backdropClick : undefined }),
            React.isValidElement(modal.content) &&
                React.cloneElement(modal.content, {
                    onDismiss: () => handleDismiss(modal.id),
                }))))));
};
const StyledModalWrapper = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  overflow-x: hidden;
  overflow-y: auto;
`;
const StyledBackdrop = styled.div `
  background-color: #222222;
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
`;
export default Modals;
