import { useLayoutEffect } from 'react';
export const useBodyNoScroll = (noScroll) => {
    useLayoutEffect(() => {
        if (noScroll) {
            document.body.classList.add('no-scroll');
        }
        else {
            document.body.classList.remove('no-scroll');
        }
    }, [noScroll]);
};
export const useBodyClass = (toggle, className) => {
    useLayoutEffect(() => {
        if (toggle) {
            document.body.classList.add(className);
        }
        else {
            document.body.classList.remove(className);
        }
    }, [className, toggle]);
};
