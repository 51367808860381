import React from 'react';
import styled from 'styled-components';
import { Container } from '../Shared';
import logo from '~src/assets/img/iron-logo.svg';
import { screenUp } from '~src/utils/styles';
export const Footer = () => {
    return (React.createElement(StyledFooter, null,
        React.createElement(StyledContainer, null,
            React.createElement("div", null,
                "Developed by ",
                React.createElement("img", { width: "42px", height: "24px", src: logo, alt: "iron" }),
                " team."),
            React.createElement(FooterLinkList, null,
                React.createElement("li", null,
                    React.createElement("a", { href: "" }, "Market")),
                React.createElement("li", null,
                    React.createElement("a", { href: "" }, "Document")),
                React.createElement("li", null,
                    React.createElement("a", { href: "" }, "Gorvenance")),
                React.createElement("li", null,
                    React.createElement("a", { href: "" }, "Support")),
                React.createElement("li", null,
                    React.createElement("a", { href: "" }, "Term"))))));
};
const StyledFooter = styled.footer `
  grid-area: footer;
  padding: 1.5rem 0 1.875rem 0;
  border-top: 1px solid #dfe3f00a;
  color: #6f7899;
  font-size: 0.875rem;
  img {
    vertical-align: middle;
  }
`;
const StyledContainer = styled(Container) `
  ${screenUp('lg') `
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`;
const FooterLinkList = styled.ul `
  list-style: none;
  padding: 0;

  li {
    padding-bottom: 0.625rem;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${screenUp('lg') `
  
    li {
      display: inline-block;
      padding-bottom: 0;
    }

    li + li {
      margin-left: 1.25rem;
    }

  `}
`;
