import React, { useCallback, useEffect } from 'react';
import { useSpring } from '@react-spring/web';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useRemovePopup } from '../../state/application/hooks';
import { TransactionPopup } from './TransactionPopup';
import { ErrorPopup } from './ErrorPopup';
export const StyledClose = styled.div `
  position: absolute;
  right: 10px;
  top: 10px;
  :hover {
    cursor: pointer;
  }
`;
export const Popup = styled.div `
  display: inline-block;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  background: var(--popup-bg);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    display: block;
    min-width: 290px;
  }
`;
const Fader = styled.div `
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: var(--color-black);
`;
const AnimatedFader = animated(Fader);
export const PopupItem = ({ removeAfterMs, content, popKey }) => {
    const removePopup = useRemovePopup();
    const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup]);
    useEffect(() => {
        if (removeAfterMs === null)
            return undefined;
        const timeout = setTimeout(() => {
            removeThisPopup();
        }, removeAfterMs);
        return () => {
            clearTimeout(timeout);
        };
    }, [removeAfterMs, removeThisPopup]);
    let popupContent;
    if ('txn' in content) {
        const { txn: { hash, success, summary, errorMessage }, } = content;
        popupContent = (React.createElement(TransactionPopup, { hash: hash, success: success, summary: summary, errorMessage: errorMessage }));
    }
    if ('error' in content) {
        const { error: { message, title }, } = content;
        popupContent = React.createElement(ErrorPopup, { message: message, title: title });
    }
    const faderStyle = useSpring({
        from: { width: '100%' },
        to: { width: '0%' },
        config: { duration: removeAfterMs !== null && removeAfterMs !== void 0 ? removeAfterMs : undefined },
    });
    return (React.createElement(Popup, null,
        React.createElement(StyledClose, { onClick: removeThisPopup },
            React.createElement("i", { className: "fal fa-times" })),
        popupContent,
        removeAfterMs !== null ? React.createElement(AnimatedFader, { style: faderStyle }) : null));
};
