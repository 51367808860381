import { createAction } from '@reduxjs/toolkit';
export const balanceFetched = createAction('user/balanceFetched', (chainId, data) => {
    const balances = data.reduce((memo, item) => {
        return Object.assign(Object.assign({}, memo), { [item.token]: item.balance });
    }, {});
    return {
        payload: {
            chainId,
            balances,
        },
    };
});
export const userMarketPositionFetched = createAction('user/userMarketPositionFetched', (chainId, data) => {
    const balances = {};
    const borrowing = {};
    const supplying = {};
    const allowance = {};
    data.forEach((item) => {
        balances[item.underlying] = item.walletBalance;
        borrowing[item.marketAddress] = item.borrowBalance;
        supplying[item.marketAddress] = item.xTokenBalance;
        allowance[item.marketAddress] = item.allowance;
    });
    return {
        payload: {
            chainId,
            balances,
            borrowing,
            supplying,
            allowance,
        },
    };
});
export const fetchEnteredMarkets = createAction('user/fetchAssets');
export const enterMarket = createAction('user/enterMarket');
export const exitMarket = createAction('user/exitMarket');
export const approveErc20 = createAction('user/approveErc20');
export const userRewardFetched = createAction('user/rewardFetched');
export const userLiquidityFetched = createAction('user/liquidityFetched');
