var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import styled from 'styled-components';
export const AddressIcon = (_a) => {
    var { address, size } = _a, props = __rest(_a, ["address", "size"]);
    const colors = useMemo(() => {
        return address ? getColor(address) : '';
    }, [address]);
    return React.createElement(Icon, Object.assign({ colors: colors, style: { width: size, height: size } }, props));
};
const Icon = styled.div `
  background: linear-gradient(45deg, ${(p) => p.colors});
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  flex-shrink: 0;
`;
const getColor = (address) => {
    const re = /[\da-f]{10}/gi;
    let match;
    const colors = [];
    // eslint-disable-next-line no-cond-assign
    while ((match = re.exec(address))) {
        colors.push(`hsl(${color(match.toString()).join(',')})`);
    }
    const stop = 100 / (colors.length - 1);
    return colors
        .map((t, index) => {
        return `${t} ${index * stop}%`;
    })
        .join(', ');
};
const color = (hex) => {
    const SaturationArray = [64, 76, 100];
    const LightnessArray = [40, 50, 64];
    const hash = parseInt(hex, 16);
    const h = hash % 359;
    const s = SaturationArray[Math.ceil(hash / 360) % SaturationArray.length];
    const l = LightnessArray[Math.ceil(hash / 360 / SaturationArray.length) % LightnessArray.length];
    return [h + 'deg', s + '%', l + '%'];
};
