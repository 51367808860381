import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddPopup, useBlockNumber } from '../application/hooks';
import { checkedTransaction, finalizeTransaction } from './actions';
import { Interface } from '@ethersproject/abi';
import { parseComptrollerError } from '~src/libs/ComptrollerErrorReporter';
import { parseTokenError } from '~src/libs/TokenErrorReporter';
const reportErrorMessage = (errorReporter, failure) => {
    if (errorReporter == 'comptroller') {
        return parseComptrollerError(failure[0], failure[1]);
    }
    return parseTokenError(failure[0], failure[1]);
};
const errorIface = new Interface(['event Failure(uint256, uint256, uint256)']);
const errorTopic = errorIface.getEventTopic('Failure');
const getFailure = (t) => {
    return t.logs
        .filter((x) => x.topics.some((t) => t === errorTopic))
        .map((t) => errorIface.parseLog(t).args.map((x) => x.toNumber()))[0];
};
export function shouldCheck(lastBlockNumber, tx) {
    if (tx.receipt)
        return false;
    if (!tx.lastCheckedBlockNumber)
        return true;
    const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
    if (blocksSinceCheck < 1)
        return false;
    const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
    if (minutesPending > 60) {
        // every 10 blocks if pending for longer than an hour
        return blocksSinceCheck > 9;
    }
    else if (minutesPending > 5) {
        // every 3 blocks if pending more than 5 minutes
        return blocksSinceCheck > 2;
    }
    else {
        // otherwise every block
        return true;
    }
}
export default function Updater() {
    var _a;
    const lastBlockNumber = useBlockNumber();
    const dispatch = useDispatch();
    const state = useSelector((state) => state.transactions);
    const { library: provider, chainId } = useWeb3React();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const transactions = chainId ? (_a = state[chainId]) !== null && _a !== void 0 ? _a : {} : {};
    // show popup on confirm
    const addPopup = useAddPopup();
    useEffect(() => {
        if (!chainId || !lastBlockNumber) {
            return;
        }
        Object.keys(transactions)
            .filter((hash) => shouldCheck(lastBlockNumber, transactions[hash]))
            .forEach((hash) => {
            provider
                .getTransactionReceipt(hash)
                .then((receipt) => {
                var _a, _b, _c, _d;
                if (receipt) {
                    const failure = getFailure(receipt);
                    dispatch(finalizeTransaction({
                        chainId,
                        hash,
                        receipt: {
                            blockHash: receipt.blockHash,
                            blockNumber: receipt.blockNumber,
                            contractAddress: receipt.contractAddress,
                            from: receipt.from,
                            status: receipt.status,
                            to: receipt.to,
                            transactionHash: receipt.transactionHash,
                            transactionIndex: receipt.transactionIndex,
                            failure,
                        },
                    }));
                    if (failure) {
                        addPopup({
                            txn: {
                                hash,
                                success: false,
                                summary: (_a = transactions[hash]) === null || _a === void 0 ? void 0 : _a.summary,
                                errorMessage: (_c = reportErrorMessage((_b = transactions[hash]) === null || _b === void 0 ? void 0 : _b.errorReporter, failure)) === null || _c === void 0 ? void 0 : _c.info,
                            },
                        }, hash);
                    }
                    else {
                        addPopup({
                            txn: {
                                hash,
                                success: receipt.status == 1,
                                summary: (_d = transactions[hash]) === null || _d === void 0 ? void 0 : _d.summary,
                            },
                        }, hash);
                    }
                }
                else {
                    dispatch(checkedTransaction({ chainId, hash, blockNumber: lastBlockNumber }));
                }
            })
                .catch((error) => {
                console.error(`failed to check transaction hash: ${hash}`, error);
            });
        });
    }, [chainId, transactions, lastBlockNumber, dispatch, addPopup, provider]);
    return null;
}
