import { nanoid } from '@reduxjs/toolkit';
import React, { useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
export const Toggle = ({ checked, disabled, onClick }) => {
    const id = useRef(nanoid());
    const ref = useRef();
    useLayoutEffect(() => {
        ref.current.checked = checked;
    }, [checked]);
    return (React.createElement(StyledToggle, null,
        React.createElement("input", { disabled: disabled, ref: ref, type: "checkbox", id: id.current }),
        React.createElement("label", { onClick: onClick, htmlFor: id.current }, "Toggle")));
};
const StyledToggle = styled.span `
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 2em;
    height: 1em;
    display: inline-block;
    position: relative;
  }

  label:before {
    content: '';
    width: 2em;
    height: 0.6em;
    top: 0.2em;
    border-radius: 100px;
    background: #818181;
    left: 0;
    position: absolute;
    opacity: 0.54;
  }

  label:after {
    content: '';
    position: absolute;
    top: 0em;
    left: 0em;
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    transition: 0.1s;
    background: #797c8a;
  }

  input:checked + label:before {
    background: var(--color-green);
    opacity: 0.65;
  }

  input:checked + label:after {
    left: 100%;
    background: var(--color-green);
    transform: translateX(-100%);
  }

  input:not(:disabled) + label:active:after {
    width: 1.3em;
  }
`;
