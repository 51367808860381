import React, { useMemo } from 'react';
import USDCLogo from '~src/assets/tokens/USDC.png';
import DOLLARLogo from '~src/assets/tokens/IRON.png';
import TITANLogo from '~src/assets/tokens/TITAN.png';
import ETHLogo from '~src/assets/tokens/ETH.png';
import BTCBLogo from '~src/assets/tokens/BTCB.png';
import DNDLogo from '~src/assets/tokens/DND.png';
import ADALogo from '~src/assets/tokens/ADA.png';
import DOTLogo from '~src/assets/tokens/DOT.png';
import DBTCLogo from '~src/assets/tokens/DBTC.png';
import DBNBLogo from '~src/assets/tokens/DBNB.png';
import DETHLogo from '~src/assets/tokens/DETH.png';
import DADALogo from '~src/assets/tokens/DADA.png';
import DDOTLogo from '~src/assets/tokens/DDOT.png';
import MATICLogo from '~src/assets/tokens/MATIC.png';
import VIBLogo from '~src/assets/tokens/VIB.png';
import ICELogo from '~src/assets/tokens/ICE.png';
import styled from 'styled-components';
const logosBySymbol = {
    USDC: USDCLogo,
    IRON: DOLLARLogo,
    TITAN: TITANLogo,
    ETH: ETHLogo,
    WETH: ETHLogo,
    ADA: ADALogo,
    MATIC: MATICLogo,
    DOT: DOTLogo,
    BTCB: BTCBLogo,
    DND: DNDLogo,
    DBTC: DBTCLogo,
    DBNB: DBNBLogo,
    DETH: DETHLogo,
    DADA: DADALogo,
    DDOT: DDOTLogo,
    VIB: VIBLogo,
    ICE: ICELogo,
};
export const TokenSymbol = ({ symbol, size = 64 }) => {
    const url = useMemo(() => {
        return symbol ? logosBySymbol[symbol.toUpperCase()] : '';
        // return `/public/assets/${symbol.toLowerCase()}.png`;
    }, [symbol]);
    if (!url) {
        return React.createElement(React.Fragment, null);
    }
    return React.createElement(StyledTokenSymbol, { src: url, alt: `${symbol} Logo`, width: size, height: size });
};
const StyledTokenSymbol = styled.img `
  vertical-align: middle;
  display: inline-block;
  position: relative;
`;
