import { createReducer } from '@reduxjs/toolkit';
import { comptrollerInfoFetched } from './actions';
const initialState = {};
const reducer = createReducer(initialState, (builder) => {
    builder.addCase(comptrollerInfoFetched, (state, { payload: { chainId, closeFactor, liquidationIncentive } }) => {
        if (!chainId) {
            return;
        }
        if (!state[chainId]) {
            state[chainId] = { closeFactor, liquidationIncentive };
        }
        else {
            state[chainId].closeFactor = closeFactor;
            state[chainId].liquidationIncentive = liquidationIncentive;
        }
    });
});
export default reducer;
