import { formatUnits } from '@ethersproject/units';
import React, { useMemo } from 'react';
export const BigNumberValue = ({ value, decimals, fractionDigits: _fractionDigits, keepTrailingZeros: _keepTrailingZeros, keepCommas, percentage, }) => {
    const text = useMemo(() => {
        const fractionDigits = _fractionDigits == null ? 6 : _fractionDigits;
        if (!value) {
            return '--';
        }
        const a = formatUnits(value, decimals);
        return new Intl.NumberFormat('en-US', {
            notation: 'compact',
            useGrouping: keepCommas,
            style: percentage ? 'percent' : 'decimal',
            maximumFractionDigits: fractionDigits,
            minimumFractionDigits: _keepTrailingZeros ? fractionDigits : 1,
        }).format(+a);
    }, [_fractionDigits, decimals, keepCommas, _keepTrailingZeros, percentage, value]);
    return React.createElement(React.Fragment, null, text);
};
