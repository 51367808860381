var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AbstractConnector } from '@web3-react/abstract-connector';
export const URI_AVAILABLE = 'URI_AVAILABLE';
const __DEV__ = false;
export class UserRejectedRequestError extends Error {
    constructor() {
        super();
        this.name = this.constructor.name;
        this.message = 'The user rejected the request.';
    }
}
function getSupportedChains({ supportedChainIds, rpc, }) {
    if (supportedChainIds) {
        return supportedChainIds;
    }
    return rpc ? Object.keys(rpc).map((k) => Number(k)) : undefined;
}
export class WalletConnectConnector extends AbstractConnector {
    constructor(config) {
        super({ supportedChainIds: getSupportedChains(config) });
        this.config = config;
        this.handleChainChanged = this.handleChainChanged.bind(this);
        this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
        this.handleDisconnect = this.handleDisconnect.bind(this);
    }
    handleChainChanged(chainId) {
        if (__DEV__) {
            console.log("Handling 'chainChanged' event with payload", chainId);
        }
        this.emitUpdate({ chainId });
    }
    handleAccountsChanged(accounts) {
        if (__DEV__) {
            console.log("Handling 'accountsChanged' event with payload", accounts);
        }
        this.emitUpdate({ account: accounts[0] });
    }
    handleDisconnect() {
        if (__DEV__) {
            console.log("Handling 'disconnect' event");
        }
        this.emitDeactivate();
        // we have to do this because of a @walletconnect/web3-provider bug
        if (this.walletConnectProvider) {
            this.walletConnectProvider.stop();
            this.walletConnectProvider.removeListener('chainChanged', this.handleChainChanged);
            this.walletConnectProvider.removeListener('accountsChanged', this.handleAccountsChanged);
            this.walletConnectProvider = undefined;
        }
        this.emitDeactivate();
    }
    activate() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.walletConnectProvider) {
                const WalletConnectProvider = yield import('@walletconnect/web3-provider').then((m) => m.default);
                this.walletConnectProvider = new WalletConnectProvider(this.config);
            }
            // ensure that the uri is going to be available, and emit an event if there's a new uri
            if (!this.walletConnectProvider.wc.connected) {
                yield this.walletConnectProvider.wc.createSession({
                    chainId: this.supportedChainIds && this.supportedChainIds.length > 0
                        ? this.supportedChainIds[0]
                        : 1,
                });
                this.emit(URI_AVAILABLE, this.walletConnectProvider.wc.uri);
            }
            const account = yield this.walletConnectProvider
                .enable()
                .then((accounts) => accounts[0])
                .catch((error) => {
                // TODO ideally this would be a better check
                if (error.message === 'User closed modal') {
                    throw new UserRejectedRequestError();
                }
                throw error;
            });
            this.walletConnectProvider.on('disconnect', this.handleDisconnect);
            this.walletConnectProvider.on('chainChanged', this.handleChainChanged);
            this.walletConnectProvider.on('accountsChanged', this.handleAccountsChanged);
            return { provider: this.walletConnectProvider, account };
        });
    }
    getProvider() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.walletConnectProvider;
        });
    }
    getChainId() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.walletConnectProvider.send('eth_chainId');
        });
    }
    getAccount() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.walletConnectProvider
                .send('eth_accounts')
                .then((accounts) => accounts[0]);
        });
    }
    deactivate() {
        if (this.walletConnectProvider) {
            this.walletConnectProvider.stop();
            this.walletConnectProvider.removeListener('disconnect', this.handleDisconnect);
            this.walletConnectProvider.removeListener('chainChanged', this.handleChainChanged);
            this.walletConnectProvider.removeListener('accountsChanged', this.handleAccountsChanged);
        }
    }
    close() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            yield ((_a = this.walletConnectProvider) === null || _a === void 0 ? void 0 : _a.close());
        });
    }
}
