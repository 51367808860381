var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from 'react';
import { useComptroller, useLens } from '../providers/ContractProvider';
import { useMulticall } from './useMulticall';
import { flatten } from 'lodash';
import { useWeb3React } from '@web3-react/core';
import { config } from '../config';
import { BlocksPerYear } from '../utils/constants';
const buildCall = (comptroller, marketAddress) => {
    return [
        {
            target: marketAddress,
            signature: 'supplyRatePerBlock() returns (uint256)',
        },
        {
            target: marketAddress,
            signature: 'exchangeRateStored() returns (uint256)',
        },
        {
            target: marketAddress,
            signature: 'borrowRatePerBlock() returns (uint256)',
        },
        {
            target: marketAddress,
            signature: 'reserveFactorMantissa() returns (uint256)',
        },
        {
            target: marketAddress,
            signature: 'totalBorrows() returns (uint256)',
        },
        {
            target: marketAddress,
            signature: 'totalReserves() returns (uint256)',
        },
        {
            target: marketAddress,
            signature: 'totalSupply() returns (uint256)',
        },
        {
            target: marketAddress,
            signature: 'getCash() returns (uint256)',
        },
        {
            target: comptroller,
            signature: 'markets(address) external view returns (bool isListed, uint256 collateralFactor)',
            params: [marketAddress],
        },
        {
            target: comptroller,
            signature: 'compSpeeds(address) external view returns (uint256)',
            params: [marketAddress],
        },
        {
            target: comptroller,
            signature: 'borrowCaps(address) external view returns (uint256)',
            params: [marketAddress],
        },
    ];
};
const parseResponse = (result) => {
    return {
        supplyRatePerBlock: result[0][0],
        exchangeRateStored: result[1][0],
        borrowRatePerBlock: result[2][0],
        reserveFactor: result[3][0],
        totalBorrows: result[4][0],
        totalReserves: result[5][0],
        totalSupply: result[6][0],
        cash: result[7][0],
        isListed: result[8][0],
        collateralFactor: result[8][1],
        compSpeed: result[9][0],
        borrowCap: result[10][0],
    };
};
export const useGetMarketsMetadata = () => {
    const comptroller = useComptroller();
    const lens = useLens();
    const multicall = useMulticall();
    const { chainId } = useWeb3React();
    const allMarkets = useMemo(() => {
        return config.markets[chainId] || [];
    }, [chainId]);
    const getMetadata = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!comptroller) {
            return [];
        }
        const calls = flatten(allMarkets.map((t) => buildCall(comptroller.address, t.marketAddress)));
        const response = yield multicall(calls);
        return allMarkets.map((market, index) => {
            const chunkSize = 11;
            const data = response.slice(index * chunkSize, (index + 1) * chunkSize);
            const info = parseResponse(data);
            return Object.assign(Object.assign({}, market), { exchangeRate: info.exchangeRateStored, cash: info.cash, totalSupply: info.totalSupply, totalReserves: info.totalReserves, totalBorrows: info.totalBorrows, supplyRatePerBlock: info.supplyRatePerBlock, supplyRatePerYear: info.supplyRatePerBlock.mul(BlocksPerYear), borrowRatePerBlock: info.borrowRatePerBlock, borrowRatePerYear: info.borrowRatePerBlock.mul(BlocksPerYear), collateralFactor: info.collateralFactor, compSpeed: info.compSpeed, reserveFactor: info.reserveFactor, borrowCap: info.borrowCap });
        });
    }), [allMarkets, comptroller, multicall]);
    const getPrices = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!lens) {
            return;
        }
        const marketAddresses = allMarkets.map((t) => t.marketAddress);
        const data = (yield lens.cTokenUnderlyingPriceAll(marketAddresses));
        return data.reduce((memo, [token, price]) => {
            return Object.assign(Object.assign({}, memo), { [token]: price });
        }, {});
    }), [allMarkets, lens]);
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        let [metadata, prices] = yield Promise.all([getMetadata(), getPrices()]);
        metadata = metadata.map((t) => {
            return Object.assign(Object.assign({}, t), { underlyingPrice: prices[t.marketAddress] });
        });
        return { metadata, chainId };
    }), [chainId, getMetadata, getPrices]);
};
