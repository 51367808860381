import { useCallback, useMemo } from 'react';
import { addPopup, removePopup, toggleWalletModal, toggleSettingsMenu, hideInfoBox, setSlippageTolerance, connectToAccount, disconnectAccount, showInfoBox, acceptTerms, setUseZap, } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
export function useBlockNumber() {
    const { chainId } = useWeb3React();
    return useSelector((state) => state.application.blockNumber[chainId !== null && chainId !== void 0 ? chainId : -1]);
}
export function useWalletModalOpen() {
    return useSelector((state) => state.application.walletModalOpen);
}
export function useWalletModalToggle() {
    const dispatch = useDispatch();
    return useCallback(() => dispatch(toggleWalletModal()), [dispatch]);
}
export function useSettingsMenuOpen() {
    return useSelector((state) => state.application.settingsMenuOpen);
}
export function useToggleSettingsMenu() {
    const dispatch = useDispatch();
    return useCallback(() => dispatch(toggleSettingsMenu()), [dispatch]);
}
// returns a function that allows adding a popup
export function useAddPopup() {
    const dispatch = useDispatch();
    return useCallback((content, key) => {
        dispatch(addPopup({ content, key }));
    }, [dispatch]);
}
// returns a function that allows removing a popup via its key
export function useRemovePopup() {
    const dispatch = useDispatch();
    return useCallback((key) => {
        dispatch(removePopup({ key }));
    }, [dispatch]);
}
// get the list of active popups
export function useActivePopups() {
    const list = useSelector((state) => state.application.popupList);
    return useMemo(() => list.filter((item) => item.show), [list]);
}
// check if infobox was previously hidden
export function useIsHiddenInfoBox(key) {
    const list = useSelector((state) => state.application.infoBoxList);
    return useMemo(() => { var _a; return (_a = list[key]) !== null && _a !== void 0 ? _a : false; }, [key, list]);
}
// hide Infobox for one session
export function useHideInfoBox() {
    const dispatch = useDispatch();
    return useCallback((key) => {
        dispatch(hideInfoBox({ key }));
    }, [dispatch]);
}
// hide Infobox for one session
export function useShowInfoBox() {
    const dispatch = useDispatch();
    return useCallback((key) => {
        dispatch(showInfoBox({ key }));
    }, [dispatch]);
}
export function useSetSlipageTolerance() {
    const dispatch = useDispatch();
    return useCallback((slippage) => {
        dispatch(setSlippageTolerance({ slippage }));
    }, [dispatch]);
}
export function useSetZap() {
    const dispatch = useDispatch();
    return useCallback((isZap) => {
        dispatch(setUseZap({ isZap }));
    }, [dispatch]);
}
export function useGetSlippageTolerance() {
    const slippage = useSelector((state) => state.application.slippageTolerance);
    return slippage;
}
export function useGetIsZap() {
    const isZap = useSelector((state) => state.application.isZap);
    return isZap;
}
export function useGetConnectedAccount() {
    return useSelector((state) => state.application.account);
}
export function useSetConnectedAccount() {
    const dispatch = useDispatch();
    return useCallback((account, connector) => {
        dispatch(connectToAccount({ account, connector: connector }));
    }, [dispatch]);
}
export function useDisconnectAccount() {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(disconnectAccount());
    }, [dispatch]);
}
export function useAcceptTerms() {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(acceptTerms());
    }, [dispatch]);
}
export function useIsAcceptedTerms() {
    const accepted = useSelector((state) => state.application.acceptedTerms);
    return accepted;
}
export function useSavedConnector() {
    const connector = useSelector((state) => state.application.connector);
    return connector;
}
