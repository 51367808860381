import React, { useCallback, useMemo } from 'react';
import { Range } from 'react-range';
import styled from 'styled-components';
export const ValueRange = ({ steps, onChange, max, maxLabel, value, }) => {
    max = max || 100;
    const values = useMemo(() => {
        if (value > 100) {
            return [100];
        }
        return [value];
    }, [value]);
    const onValueChange = useCallback((v) => {
        // setValue(v);
        if (onChange) {
            onChange(v[0]);
        }
    }, [onChange]);
    const onMarkClick = useCallback((ev) => {
        ev.stopPropagation();
        ev.preventDefault();
        const value = +ev.currentTarget.dataset.rate;
        onValueChange([value]);
        return false;
    }, [onValueChange]);
    const renderMark = useCallback(({ props, index }) => {
        if (!steps) {
            return;
        }
        const mark = steps.find((t) => t.rate === index);
        if (mark) {
            return (React.createElement(StyledMark, Object.assign({}, props, { onClick: onMarkClick, "data-rate": mark.rate }),
                React.createElement("span", null, mark.label)));
        }
        return null;
    }, [onMarkClick, steps]);
    const onMax = useCallback(() => {
        onValueChange([max]);
    }, [max, onValueChange]);
    const onMin = useCallback(() => {
        onValueChange([0]);
    }, [onValueChange]);
    return (React.createElement(StyledValueRange, null,
        React.createElement(MinLabel, { onClick: onMin }, "0%"),
        React.createElement("div", { className: "range" },
            React.createElement(Range, { step: 1, min: 0, max: max, values: values, onChange: onValueChange, renderTrack: ({ props, children }) => React.createElement(Track, Object.assign({}, props), children), renderThumb: ({ props, value }) => React.createElement(Thumb, Object.assign({}, props),
                    value,
                    "%"), renderMark: renderMark })),
        React.createElement(MaxLabel, { onClick: onMax }, maxLabel || 'MAX')));
};
const MinLabel = styled.span `
  font-size: 0.8em;
`;
const MaxLabel = styled.span `
  font-size: 0.8em;
`;
const StyledValueRange = styled.div `
  display: flex;
  align-items: center;
  padding-top: 15px;
  .range {
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const Track = styled.div `
  background-color: var(--range-track-bg);
  height: 2px;
  width: 100%;
  border-radius: 2px;
`;
const Thumb = styled.div `
  border: var(--range-thumb-border);
  border-radius: 1000px;
  padding: 2px 5px;
  color: var(--color-blue);
  background-color: var(--color-black);
  font-size: 0.7em;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    box-shadow: var(--range-thumb-focus-shadow);
  }
`;
const StyledMark = styled.div `
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--range-mark-bg);
  position: relative;

  &:hover {
    background-color: var(--color-white);

    span {
      color: var(--color-white);
      visibility: visible;
    }
  }

  span {
    visibility: hidden;
    position: absolute;
    color: var(--range-mark-bg);
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.7em;
    white-space: nowrap;
    padding: 5px;
  }
`;
