var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { config } from '~src/config';
import { useErc20 } from '~src/hooks/useErc20';
import { useGetAccountLiquidity } from '~src/hooks/useGetAccountLiquidity';
import { useGetUserPositionInMarket } from '../../hooks/useGetUserPositionInMarket';
import { useComptroller } from '../../providers/ContractProvider';
import { usePendingTransactionCount } from '../transactions/hooks';
import { fetchEnteredMarkets, userMarketPositionFetched, userLiquidityFetched, userRewardFetched, } from './actions';
const Updater = () => {
    var _a;
    const getUserInfo = useGetUserPositionInMarket();
    const dispatch = useDispatch();
    const { chainId, account } = useWeb3React();
    const comptroller = useComptroller();
    const pendingTransactionCount = usePendingTransactionCount();
    const reward = useErc20((_a = config.contracts[chainId]) === null || _a === void 0 ? void 0 : _a.reward);
    const getAssetsIn = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!comptroller || !account) {
            return [];
        }
        return (yield comptroller.getAssetsIn(account));
    }), [comptroller, account]);
    const getReward = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!account || !reward || !comptroller) {
            return {};
        }
        const [balance, accrued] = yield Promise.all([
            reward.balanceOf(account),
            comptroller.compAccrued(account),
        ]);
        return { balance, accrued };
    }), [account, comptroller, reward]);
    const getAccountLiquidity = useGetAccountLiquidity();
    useEffect(() => {
        if (!account) {
            dispatch(userMarketPositionFetched(chainId, []));
            dispatch(fetchEnteredMarkets({ chainId, markets: [] }));
            return;
        }
        let mounted = true;
        Promise.all([
            getUserInfo(account),
            getAssetsIn(),
            getReward(),
            getAccountLiquidity(account),
        ]).then(([userInfo, assetsIn, rewardInfo, accountLiquidity]) => {
            if (!mounted) {
                return;
            }
            if (chainId) {
                dispatch(userMarketPositionFetched(chainId, userInfo.data));
                dispatch(userRewardFetched(Object.assign({ chainId }, rewardInfo)));
                dispatch(fetchEnteredMarkets({ chainId, markets: assetsIn }));
                dispatch(userLiquidityFetched(Object.assign({ chainId }, accountLiquidity)));
            }
        });
        return () => {
            mounted = false;
        };
    }, [
        account,
        chainId,
        dispatch,
        getAccountLiquidity,
        getAssetsIn,
        getReward,
        getUserInfo,
        pendingTransactionCount,
    ]);
    return null;
};
export default Updater;
