import React, { useMemo } from 'react';
import { parseComptrollerError } from '~src/libs/ComptrollerErrorReporter';
import { parseTokenError } from '~src/libs/TokenErrorReporter';
import { ExplorerLink } from '../Shared/ExplorerLink';
export const TransactionRow = (tx) => {
    var _a, _b, _c, _d;
    const pending = !tx.receipt;
    const failed = (_b = (_a = tx === null || tx === void 0 ? void 0 : tx.receipt) === null || _a === void 0 ? void 0 : _a.failure) === null || _b === void 0 ? void 0 : _b.length;
    const success = !pending &&
        !failed &&
        tx &&
        (((_c = tx.receipt) === null || _c === void 0 ? void 0 : _c.status) === 1 || typeof ((_d = tx.receipt) === null || _d === void 0 ? void 0 : _d.status) === 'undefined');
    const failure = useMemo(() => {
        var _a;
        if (!((_a = tx.receipt) === null || _a === void 0 ? void 0 : _a.failure)) {
            return {
                info: '',
            };
        }
        if (tx.errorReporter == 'comptroller') {
            return parseComptrollerError(tx.receipt.failure[0], tx.receipt.failure[1]);
        }
        return parseTokenError(tx.receipt.failure[0], tx.receipt.failure[1]);
    }, [tx]);
    return (React.createElement("tr", null,
        React.createElement("td", null,
            React.createElement(ExplorerLink, { type: "tx", address: tx.hash }, tx.summary || tx.hash)),
        React.createElement("td", null, pending ? (React.createElement("i", { className: "far fa-circle-notch fa-spin" })) : success ? (React.createElement("i", { className: "fas fa-check-circle text-green" })) : (React.createElement("i", { className: "fas fa-exclamation-circle text-red", title: failure === null || failure === void 0 ? void 0 : failure.info })))));
};
