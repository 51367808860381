var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useTransactionAdder } from '../state/transactions/hooks';
import { useAddPopup } from '../state/application/hooks';
import useModalWithFC from './useModalWithFC';
import { WaitingApproval, WaitingApprovalResult } from '../components';
class CompError extends Error {
    constructor(code) {
        super('Compound failure');
        this.reasons = {
            1: '',
        };
        this.reason = this.reasons[code] || code.toString();
    }
}
export const thowIfFailure = (tx) => __awaiter(void 0, void 0, void 0, function* () {
    const receipt = (yield tx.wait());
    const error = receipt.events.find((x) => x.event === 'Failure');
    if (error) {
        console.warn(error);
        throw new CompError(error.args.error.toNumber());
    }
});
const useHandleTransactionReceipt = () => {
    const { showModal, hideModal } = useModalWithFC('approval');
    const addTransaction = useTransactionAdder();
    const addPopup = useAddPopup();
    return useCallback((promise, summary, customData) => __awaiter(void 0, void 0, void 0, function* () {
        showModal(WaitingApproval, {
            message: summary,
            onDismiss: hideModal,
        });
        try {
            customData = customData || {};
            const transactionResponse = yield promise;
            showModal(WaitingApprovalResult, {
                onDismiss: hideModal,
                transactionHash: transactionResponse.hash,
            });
            addTransaction(transactionResponse, Object.assign(Object.assign({}, customData), { summary }));
            return transactionResponse;
        }
        catch (err) {
            hideModal();
            const { title, message } = getErrorMessage(err, summary);
            addPopup({ error: { message, title } });
            throw err;
        }
    }), [showModal, hideModal, addTransaction, addPopup]);
};
export default useHandleTransactionReceipt;
export function getErrorMessage(err, summary) {
    console.debug(err);
    return {
        title: summary,
        message: err.message || `Failed to ${summary}`,
    };
}
