import { useCallback, useContext, useRef } from 'react';
import { Context } from '../providers/Modals';
const useModal = (modal, id, backdropClick = true) => {
    const { onDismiss, onPresent } = useContext(Context);
    const ref = useRef(id || '');
    const handlePresent = useCallback(() => {
        modal;
        ref.current = onPresent(modal, backdropClick);
    }, [backdropClick, modal, onPresent]);
    const handleDismiss = useCallback(() => {
        onDismiss(ref.current);
    }, [onDismiss]);
    return [handlePresent, handleDismiss];
};
export default useModal;
