import { createReducer } from '@reduxjs/toolkit';
import { approveErc20, balanceFetched, enterMarket, exitMarket, fetchEnteredMarkets, userMarketPositionFetched, userRewardFetched, } from './actions';
const initialUserInfo = {};
const initialState = {};
export default createReducer(initialState, (builder) => {
    builder.addCase(balanceFetched, (state, { payload }) => {
        state[payload.chainId] = Object.assign(Object.assign({}, (state[payload.chainId] || initialUserInfo)), { walletBalances: payload.balances });
    });
    builder.addCase(fetchEnteredMarkets, (state, { payload }) => {
        const { chainId, markets } = payload;
        state[chainId] = Object.assign(Object.assign({}, (state[chainId] || initialUserInfo)), { enteredMarkets: markets });
    });
    builder.addCase(userMarketPositionFetched, (state, { payload }) => {
        const { chainId, balances, borrowing, supplying, allowance } = payload;
        state[chainId] = Object.assign(Object.assign({}, (state[chainId] || initialUserInfo)), { walletBalances: balances, supplying,
            borrowing,
            allowance });
    });
    builder.addCase(enterMarket, (state, { payload: { chainId, market } }) => {
        if (state[chainId]) {
            state[chainId].enteredMarkets.push(market);
        }
    });
    builder.addCase(exitMarket, (state, { payload: { chainId, market } }) => {
        if (state[chainId]) {
            state[chainId].enteredMarkets = state[chainId].enteredMarkets.filter((x) => x != market);
        }
    });
    builder.addCase(approveErc20, (state, { payload: { chainId, spender, amount } }) => {
        if (state[chainId]) {
            state[chainId].allowance[spender] = amount;
        }
    });
    builder.addCase(userRewardFetched, (state, { payload: { chainId, balance, accrued } }) => {
        if (state[chainId]) {
            state[chainId].rewardAccrued = accrued;
            state[chainId].rewardBalance = balance;
        }
    });
});
