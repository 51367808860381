import IERC20 from '../abis/IERC20.json';
import { Contract } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
import { MaxUint256 } from '@ethersproject/constants';
import { useNativeTokenSymbol } from './useNativeTokenSymbol';
export class WrappedNativeToken {
    constructor(provider) {
        this.provider = provider;
    }
    balanceOf(address) {
        return this.provider.getBalance(address);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    allowance(_account, _spender) {
        return Promise.resolve(MaxUint256);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    approve(_spender, amount) {
        return Promise.resolve(true);
    }
}
export const useErc20 = (address) => {
    const nativeToken = useNativeTokenSymbol();
    const { library, account } = useWeb3React();
    return useMemo(() => {
        if (!library || !address) {
            return;
        }
        const provider = account ? library.getSigner(account) : library;
        if (address !== nativeToken) {
            return new Contract(address, IERC20.abi, provider);
        }
        return new WrappedNativeToken(provider);
    }, [library, address, account, nativeToken]);
};
