import React from 'react';
import styled from 'styled-components';
import { Button, ExplorerLink, Modal } from '../Shared';
export const WaitingApprovalResult = ({ transactionHash, onDismiss, }) => {
    return (React.createElement(Modal, { size: "sm" },
        React.createElement(ModalHeader, null,
            React.createElement("button", { onClick: onDismiss },
                React.createElement("i", { className: "fal fa-times" }))),
        React.createElement(StyledModalContent, null,
            React.createElement(StyledTitle, null, "Transaction Submitted"),
            React.createElement(StyledView, null,
                React.createElement(ExplorerLink, { type: "tx", address: transactionHash },
                    "View on Explorer\u00A0",
                    React.createElement("i", { className: "far fa-external-link" }))),
            React.createElement(Button, { variant: "primary", onClick: onDismiss, block: true }, "Close"))));
};
const ModalHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    color: var(--color-white);
    background-color: transparent;
    border: none;
    padding: 15px;
    cursor: pointer;
  }
`;
const StyledModalContent = styled.div `
  padding: 20px;
  text-align: center;
`;
const StyledTitle = styled.div `
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  color: var(--color-blue);
`;
const StyledView = styled.div `
  font-size: 13px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 20px;
  text-decoration: none !important;
  padding: 25px 0;
`;
