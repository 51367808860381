var TokenError;
(function (TokenError) {
    TokenError[TokenError["NO_ERROR"] = 0] = "NO_ERROR";
    TokenError[TokenError["UNAUTHORIZED"] = 1] = "UNAUTHORIZED";
    TokenError[TokenError["BAD_INPUT"] = 2] = "BAD_INPUT";
    TokenError[TokenError["COMPTROLLER_REJECTION"] = 3] = "COMPTROLLER_REJECTION";
    TokenError[TokenError["COMPTROLLER_CALCULATION_ERROR"] = 4] = "COMPTROLLER_CALCULATION_ERROR";
    TokenError[TokenError["INTEREST_RATE_MODEL_ERROR"] = 5] = "INTEREST_RATE_MODEL_ERROR";
    TokenError[TokenError["INVALID_ACCOUNT_PAIR"] = 6] = "INVALID_ACCOUNT_PAIR";
    TokenError[TokenError["INVALID_CLOSE_AMOUNT_REQUESTED"] = 7] = "INVALID_CLOSE_AMOUNT_REQUESTED";
    TokenError[TokenError["INVALID_COLLATERAL_FACTOR"] = 8] = "INVALID_COLLATERAL_FACTOR";
    TokenError[TokenError["MATH_ERROR"] = 9] = "MATH_ERROR";
    TokenError[TokenError["MARKET_NOT_FRESH"] = 10] = "MARKET_NOT_FRESH";
    TokenError[TokenError["MARKET_NOT_LISTED"] = 11] = "MARKET_NOT_LISTED";
    TokenError[TokenError["TOKEN_INSUFFICIENT_ALLOWANCE"] = 12] = "TOKEN_INSUFFICIENT_ALLOWANCE";
    TokenError[TokenError["TOKEN_INSUFFICIENT_BALANCE"] = 13] = "TOKEN_INSUFFICIENT_BALANCE";
    TokenError[TokenError["TOKEN_INSUFFICIENT_CASH"] = 14] = "TOKEN_INSUFFICIENT_CASH";
    TokenError[TokenError["TOKEN_TRANSFER_IN_FAILED"] = 15] = "TOKEN_TRANSFER_IN_FAILED";
    TokenError[TokenError["TOKEN_TRANSFER_OUT_FAILED"] = 16] = "TOKEN_TRANSFER_OUT_FAILED";
})(TokenError || (TokenError = {}));
var TokenFailureInfo;
(function (TokenFailureInfo) {
    TokenFailureInfo[TokenFailureInfo["ACCEPT_ADMIN_PENDING_ADMIN_CHECK"] = 0] = "ACCEPT_ADMIN_PENDING_ADMIN_CHECK";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_ACCUMULATED_INTEREST_CALCULATION_FAILED"] = 1] = "ACCRUE_INTEREST_ACCUMULATED_INTEREST_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_BORROW_RATE_CALCULATION_FAILED"] = 2] = "ACCRUE_INTEREST_BORROW_RATE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_NEW_BORROW_INDEX_CALCULATION_FAILED"] = 3] = "ACCRUE_INTEREST_NEW_BORROW_INDEX_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_NEW_TOTAL_BORROWS_CALCULATION_FAILED"] = 4] = "ACCRUE_INTEREST_NEW_TOTAL_BORROWS_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_NEW_TOTAL_RESERVES_CALCULATION_FAILED"] = 5] = "ACCRUE_INTEREST_NEW_TOTAL_RESERVES_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["ACCRUE_INTEREST_SIMPLE_INTEREST_FACTOR_CALCULATION_FAILED"] = 6] = "ACCRUE_INTEREST_SIMPLE_INTEREST_FACTOR_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED"] = 7] = "BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_ACCRUE_INTEREST_FAILED"] = 8] = "BORROW_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_CASH_NOT_AVAILABLE"] = 9] = "BORROW_CASH_NOT_AVAILABLE";
    TokenFailureInfo[TokenFailureInfo["BORROW_FRESHNESS_CHECK"] = 10] = "BORROW_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED"] = 11] = "BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED"] = 12] = "BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["BORROW_MARKET_NOT_LISTED"] = 13] = "BORROW_MARKET_NOT_LISTED";
    TokenFailureInfo[TokenFailureInfo["BORROW_COMPTROLLER_REJECTION"] = 14] = "BORROW_COMPTROLLER_REJECTION";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_ACCRUE_BORROW_INTEREST_FAILED"] = 15] = "LIQUIDATE_ACCRUE_BORROW_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_ACCRUE_COLLATERAL_INTEREST_FAILED"] = 16] = "LIQUIDATE_ACCRUE_COLLATERAL_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_COLLATERAL_FRESHNESS_CHECK"] = 17] = "LIQUIDATE_COLLATERAL_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_COMPTROLLER_REJECTION"] = 18] = "LIQUIDATE_COMPTROLLER_REJECTION";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_COMPTROLLER_CALCULATE_AMOUNT_SEIZE_FAILED"] = 19] = "LIQUIDATE_COMPTROLLER_CALCULATE_AMOUNT_SEIZE_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_CLOSE_AMOUNT_IS_UINT_MAX"] = 20] = "LIQUIDATE_CLOSE_AMOUNT_IS_UINT_MAX";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_CLOSE_AMOUNT_IS_ZERO"] = 21] = "LIQUIDATE_CLOSE_AMOUNT_IS_ZERO";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_FRESHNESS_CHECK"] = 22] = "LIQUIDATE_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_LIQUIDATOR_IS_BORROWER"] = 23] = "LIQUIDATE_LIQUIDATOR_IS_BORROWER";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_REPAY_BORROW_FRESH_FAILED"] = 24] = "LIQUIDATE_REPAY_BORROW_FRESH_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_BALANCE_INCREMENT_FAILED"] = 25] = "LIQUIDATE_SEIZE_BALANCE_INCREMENT_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_BALANCE_DECREMENT_FAILED"] = 26] = "LIQUIDATE_SEIZE_BALANCE_DECREMENT_FAILED";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_COMPTROLLER_REJECTION"] = 27] = "LIQUIDATE_SEIZE_COMPTROLLER_REJECTION";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_LIQUIDATOR_IS_BORROWER"] = 28] = "LIQUIDATE_SEIZE_LIQUIDATOR_IS_BORROWER";
    TokenFailureInfo[TokenFailureInfo["LIQUIDATE_SEIZE_TOO_MUCH"] = 29] = "LIQUIDATE_SEIZE_TOO_MUCH";
    TokenFailureInfo[TokenFailureInfo["MINT_ACCRUE_INTEREST_FAILED"] = 30] = "MINT_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_COMPTROLLER_REJECTION"] = 31] = "MINT_COMPTROLLER_REJECTION";
    TokenFailureInfo[TokenFailureInfo["MINT_EXCHANGE_CALCULATION_FAILED"] = 32] = "MINT_EXCHANGE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_EXCHANGE_RATE_READ_FAILED"] = 33] = "MINT_EXCHANGE_RATE_READ_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_FRESHNESS_CHECK"] = 34] = "MINT_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["MINT_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED"] = 35] = "MINT_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_NEW_TOTAL_SUPPLY_CALCULATION_FAILED"] = 36] = "MINT_NEW_TOTAL_SUPPLY_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_TRANSFER_IN_FAILED"] = 37] = "MINT_TRANSFER_IN_FAILED";
    TokenFailureInfo[TokenFailureInfo["MINT_TRANSFER_IN_NOT_POSSIBLE"] = 38] = "MINT_TRANSFER_IN_NOT_POSSIBLE";
    TokenFailureInfo[TokenFailureInfo["REDEEM_ACCRUE_INTEREST_FAILED"] = 39] = "REDEEM_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_COMPTROLLER_REJECTION"] = 40] = "REDEEM_COMPTROLLER_REJECTION";
    TokenFailureInfo[TokenFailureInfo["REDEEM_EXCHANGE_TOKENS_CALCULATION_FAILED"] = 41] = "REDEEM_EXCHANGE_TOKENS_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_EXCHANGE_AMOUNT_CALCULATION_FAILED"] = 42] = "REDEEM_EXCHANGE_AMOUNT_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_EXCHANGE_RATE_READ_FAILED"] = 43] = "REDEEM_EXCHANGE_RATE_READ_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_FRESHNESS_CHECK"] = 44] = "REDEEM_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["REDEEM_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED"] = 45] = "REDEEM_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_NEW_TOTAL_SUPPLY_CALCULATION_FAILED"] = 46] = "REDEEM_NEW_TOTAL_SUPPLY_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDEEM_TRANSFER_OUT_NOT_POSSIBLE"] = 47] = "REDEEM_TRANSFER_OUT_NOT_POSSIBLE";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_ACCRUE_INTEREST_FAILED"] = 48] = "REDUCE_RESERVES_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_ADMIN_CHECK"] = 49] = "REDUCE_RESERVES_ADMIN_CHECK";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_CASH_NOT_AVAILABLE"] = 50] = "REDUCE_RESERVES_CASH_NOT_AVAILABLE";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_FRESH_CHECK"] = 51] = "REDUCE_RESERVES_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["REDUCE_RESERVES_VALIDATION"] = 52] = "REDUCE_RESERVES_VALIDATION";
    TokenFailureInfo[TokenFailureInfo["REPAY_BEHALF_ACCRUE_INTEREST_FAILED"] = 53] = "REPAY_BEHALF_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_ACCRUE_INTEREST_FAILED"] = 54] = "REPAY_BORROW_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED"] = 55] = "REPAY_BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_COMPTROLLER_REJECTION"] = 56] = "REPAY_BORROW_COMPTROLLER_REJECTION";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_FRESHNESS_CHECK"] = 57] = "REPAY_BORROW_FRESHNESS_CHECK";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED"] = 58] = "REPAY_BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED"] = 59] = "REPAY_BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED";
    TokenFailureInfo[TokenFailureInfo["REPAY_BORROW_TRANSFER_IN_NOT_POSSIBLE"] = 60] = "REPAY_BORROW_TRANSFER_IN_NOT_POSSIBLE";
    TokenFailureInfo[TokenFailureInfo["SET_COLLATERAL_FACTOR_OWNER_CHECK"] = 61] = "SET_COLLATERAL_FACTOR_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_COLLATERAL_FACTOR_VALIDATION"] = 62] = "SET_COLLATERAL_FACTOR_VALIDATION";
    TokenFailureInfo[TokenFailureInfo["SET_COMPTROLLER_OWNER_CHECK"] = 63] = "SET_COMPTROLLER_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_INTEREST_RATE_MODEL_ACCRUE_INTEREST_FAILED"] = 64] = "SET_INTEREST_RATE_MODEL_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["SET_INTEREST_RATE_MODEL_FRESH_CHECK"] = 65] = "SET_INTEREST_RATE_MODEL_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_INTEREST_RATE_MODEL_OWNER_CHECK"] = 66] = "SET_INTEREST_RATE_MODEL_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_MAX_ASSETS_OWNER_CHECK"] = 67] = "SET_MAX_ASSETS_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_ORACLE_MARKET_NOT_LISTED"] = 68] = "SET_ORACLE_MARKET_NOT_LISTED";
    TokenFailureInfo[TokenFailureInfo["SET_PENDING_ADMIN_OWNER_CHECK"] = 69] = "SET_PENDING_ADMIN_OWNER_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_ACCRUE_INTEREST_FAILED"] = 70] = "SET_RESERVE_FACTOR_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_ADMIN_CHECK"] = 71] = "SET_RESERVE_FACTOR_ADMIN_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_FRESH_CHECK"] = 72] = "SET_RESERVE_FACTOR_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["SET_RESERVE_FACTOR_BOUNDS_CHECK"] = 73] = "SET_RESERVE_FACTOR_BOUNDS_CHECK";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_COMPTROLLER_REJECTION"] = 74] = "TRANSFER_COMPTROLLER_REJECTION";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_NOT_ALLOWED"] = 75] = "TRANSFER_NOT_ALLOWED";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_NOT_ENOUGH"] = 76] = "TRANSFER_NOT_ENOUGH";
    TokenFailureInfo[TokenFailureInfo["TRANSFER_TOO_MUCH"] = 77] = "TRANSFER_TOO_MUCH";
    TokenFailureInfo[TokenFailureInfo["ADD_RESERVES_ACCRUE_INTEREST_FAILED"] = 78] = "ADD_RESERVES_ACCRUE_INTEREST_FAILED";
    TokenFailureInfo[TokenFailureInfo["ADD_RESERVES_FRESH_CHECK"] = 79] = "ADD_RESERVES_FRESH_CHECK";
    TokenFailureInfo[TokenFailureInfo["ADD_RESERVES_TRANSFER_IN_NOT_POSSIBLE"] = 80] = "ADD_RESERVES_TRANSFER_IN_NOT_POSSIBLE";
})(TokenFailureInfo || (TokenFailureInfo = {}));
const errorMessages = {
    [TokenError.NO_ERROR]: 'No error',
    [TokenError.UNAUTHORIZED]: 'Unauthorized',
    [TokenError.BAD_INPUT]: 'Bad input',
    [TokenError.COMPTROLLER_REJECTION]: 'Comptroller rejection',
    [TokenError.COMPTROLLER_CALCULATION_ERROR]: 'Comptroller calculation error',
    [TokenError.INTEREST_RATE_MODEL_ERROR]: 'Interest rate model error',
    [TokenError.INVALID_ACCOUNT_PAIR]: 'Invalid account pair',
    [TokenError.INVALID_CLOSE_AMOUNT_REQUESTED]: 'Invalid close amount requested',
    [TokenError.INVALID_COLLATERAL_FACTOR]: 'Invalid collateral factor',
    [TokenError.MATH_ERROR]: 'Math error',
    [TokenError.MARKET_NOT_FRESH]: 'Market not fresh',
    [TokenError.MARKET_NOT_LISTED]: 'Market not listed',
    [TokenError.TOKEN_INSUFFICIENT_ALLOWANCE]: 'Token insufficient allowance',
    [TokenError.TOKEN_INSUFFICIENT_BALANCE]: 'Token insufficient balance',
    [TokenError.TOKEN_INSUFFICIENT_CASH]: 'Token insufficient cash',
    [TokenError.TOKEN_TRANSFER_IN_FAILED]: 'Token transfer in failed',
    [TokenError.TOKEN_TRANSFER_OUT_FAILED]: 'Token transfer out failed',
};
const failureMessages = {
    [TokenFailureInfo.ACCEPT_ADMIN_PENDING_ADMIN_CHECK]: 'Accept admin pending admin check',
    [TokenFailureInfo.ACCRUE_INTEREST_ACCUMULATED_INTEREST_CALCULATION_FAILED]: 'Accrue interest accumulated interest calculation failed',
    [TokenFailureInfo.ACCRUE_INTEREST_BORROW_RATE_CALCULATION_FAILED]: 'Accrue interest borrow rate calculation failed',
    [TokenFailureInfo.ACCRUE_INTEREST_NEW_BORROW_INDEX_CALCULATION_FAILED]: 'Accrue interest new borrow index calculation failed',
    [TokenFailureInfo.ACCRUE_INTEREST_NEW_TOTAL_BORROWS_CALCULATION_FAILED]: 'Accrue interest new total borrows calculation failed',
    [TokenFailureInfo.ACCRUE_INTEREST_NEW_TOTAL_RESERVES_CALCULATION_FAILED]: 'Accrue interest new total reserves calculation failed',
    [TokenFailureInfo.ACCRUE_INTEREST_SIMPLE_INTEREST_FACTOR_CALCULATION_FAILED]: 'Accrue interest simple interest factor calculation failed',
    [TokenFailureInfo.BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED]: 'Borrow accumulated balance calculation failed',
    [TokenFailureInfo.BORROW_ACCRUE_INTEREST_FAILED]: 'Borrow accrue interest failed',
    [TokenFailureInfo.BORROW_CASH_NOT_AVAILABLE]: 'Borrow cash not available',
    [TokenFailureInfo.BORROW_FRESHNESS_CHECK]: 'Borrow freshness check',
    [TokenFailureInfo.BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED]: 'Borrow new total balance calculation failed',
    [TokenFailureInfo.BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED]: 'Borrow new account borrow balance calculation failed',
    [TokenFailureInfo.BORROW_MARKET_NOT_LISTED]: 'Borrow market not listed',
    [TokenFailureInfo.BORROW_COMPTROLLER_REJECTION]: 'Borrow comptroller rejection',
    [TokenFailureInfo.LIQUIDATE_ACCRUE_BORROW_INTEREST_FAILED]: 'Liquidate accrue borrow interest failed',
    [TokenFailureInfo.LIQUIDATE_ACCRUE_COLLATERAL_INTEREST_FAILED]: 'Liquidate accrue collateral interest failed',
    [TokenFailureInfo.LIQUIDATE_COLLATERAL_FRESHNESS_CHECK]: 'Liquidate collateral freshness check',
    [TokenFailureInfo.LIQUIDATE_COMPTROLLER_REJECTION]: 'Liquidate comptroller rejection',
    [TokenFailureInfo.LIQUIDATE_COMPTROLLER_CALCULATE_AMOUNT_SEIZE_FAILED]: 'Liquidate comptroller calculate amount seize failed',
    [TokenFailureInfo.LIQUIDATE_CLOSE_AMOUNT_IS_UINT_MAX]: 'Liquidate close amount is uint max',
    [TokenFailureInfo.LIQUIDATE_CLOSE_AMOUNT_IS_ZERO]: 'Liquidate close amount is zero',
    [TokenFailureInfo.LIQUIDATE_FRESHNESS_CHECK]: 'Liquidate freshness check',
    [TokenFailureInfo.LIQUIDATE_LIQUIDATOR_IS_BORROWER]: 'Liquidate liquidator is borrower',
    [TokenFailureInfo.LIQUIDATE_REPAY_BORROW_FRESH_FAILED]: 'Liquidate repay borrow fresh failed',
    [TokenFailureInfo.LIQUIDATE_SEIZE_BALANCE_INCREMENT_FAILED]: 'Liquidate seize balance increment failed',
    [TokenFailureInfo.LIQUIDATE_SEIZE_BALANCE_DECREMENT_FAILED]: 'Liquidate seize balance decrement failed',
    [TokenFailureInfo.LIQUIDATE_SEIZE_COMPTROLLER_REJECTION]: 'Liquidate seize comptroller rejection',
    [TokenFailureInfo.LIQUIDATE_SEIZE_LIQUIDATOR_IS_BORROWER]: 'Liquidate seize liquidator is borrower',
    [TokenFailureInfo.LIQUIDATE_SEIZE_TOO_MUCH]: 'Liquidate seize too much',
    [TokenFailureInfo.MINT_ACCRUE_INTEREST_FAILED]: 'Mint accrue interest failed',
    [TokenFailureInfo.MINT_COMPTROLLER_REJECTION]: 'Mint comptroller rejection',
    [TokenFailureInfo.MINT_EXCHANGE_CALCULATION_FAILED]: 'Mint exchange calculation failed',
    [TokenFailureInfo.MINT_EXCHANGE_RATE_READ_FAILED]: 'Mint exchange rate read failed',
    [TokenFailureInfo.MINT_FRESHNESS_CHECK]: 'Mint freshness check',
    [TokenFailureInfo.MINT_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED]: 'Mint new account balance calculation failed',
    [TokenFailureInfo.MINT_NEW_TOTAL_SUPPLY_CALCULATION_FAILED]: 'Mint new total supply calculation failed',
    [TokenFailureInfo.MINT_TRANSFER_IN_FAILED]: 'Mint transfer in failed',
    [TokenFailureInfo.MINT_TRANSFER_IN_NOT_POSSIBLE]: 'Mint transfer in not possible',
    [TokenFailureInfo.REDEEM_ACCRUE_INTEREST_FAILED]: 'Redeem accrue interest failed',
    [TokenFailureInfo.REDEEM_COMPTROLLER_REJECTION]: 'Redeem comptroller rejection',
    [TokenFailureInfo.REDEEM_EXCHANGE_TOKENS_CALCULATION_FAILED]: 'Redeem exchange tokens calculation failed',
    [TokenFailureInfo.REDEEM_EXCHANGE_AMOUNT_CALCULATION_FAILED]: 'Redeem exchange amount calculation failed',
    [TokenFailureInfo.REDEEM_EXCHANGE_RATE_READ_FAILED]: 'Redeem exchange rate read failed',
    [TokenFailureInfo.REDEEM_FRESHNESS_CHECK]: 'Redeem freshness check',
    [TokenFailureInfo.REDEEM_NEW_ACCOUNT_BALANCE_CALCULATION_FAILED]: 'Redeem new account balance calculation failed',
    [TokenFailureInfo.REDEEM_NEW_TOTAL_SUPPLY_CALCULATION_FAILED]: 'Redeem new total supply calculation failed',
    [TokenFailureInfo.REDEEM_TRANSFER_OUT_NOT_POSSIBLE]: 'Redeem transfer out not possible',
    [TokenFailureInfo.REDUCE_RESERVES_ACCRUE_INTEREST_FAILED]: 'Reduce reserves accrue interest failed',
    [TokenFailureInfo.REDUCE_RESERVES_ADMIN_CHECK]: 'Reduce reserves admin check',
    [TokenFailureInfo.REDUCE_RESERVES_CASH_NOT_AVAILABLE]: 'Reduce reserves cash not available',
    [TokenFailureInfo.REDUCE_RESERVES_FRESH_CHECK]: 'Reduce reserves fresh check',
    [TokenFailureInfo.REDUCE_RESERVES_VALIDATION]: 'Reduce reserves validation',
    [TokenFailureInfo.REPAY_BEHALF_ACCRUE_INTEREST_FAILED]: 'Repay behalf accrue interest failed',
    [TokenFailureInfo.REPAY_BORROW_ACCRUE_INTEREST_FAILED]: 'Repay borrow accrue interest failed',
    [TokenFailureInfo.REPAY_BORROW_ACCUMULATED_BALANCE_CALCULATION_FAILED]: 'Repay borrow accumulated balance calculation failed',
    [TokenFailureInfo.REPAY_BORROW_COMPTROLLER_REJECTION]: 'Repay borrow comptroller rejection',
    [TokenFailureInfo.REPAY_BORROW_FRESHNESS_CHECK]: 'Repay borrow freshness check',
    [TokenFailureInfo.REPAY_BORROW_NEW_ACCOUNT_BORROW_BALANCE_CALCULATION_FAILED]: 'Repay borrow new account borrow balance calculation failed',
    [TokenFailureInfo.REPAY_BORROW_NEW_TOTAL_BALANCE_CALCULATION_FAILED]: 'Repay borrow new total balance calculation failed',
    [TokenFailureInfo.REPAY_BORROW_TRANSFER_IN_NOT_POSSIBLE]: 'Repay borrow transfer in not possible',
    [TokenFailureInfo.SET_COLLATERAL_FACTOR_OWNER_CHECK]: 'Set collateral factor owner check',
    [TokenFailureInfo.SET_COLLATERAL_FACTOR_VALIDATION]: 'Set collateral factor validation',
    [TokenFailureInfo.SET_COMPTROLLER_OWNER_CHECK]: 'Set comptroller owner check',
    [TokenFailureInfo.SET_INTEREST_RATE_MODEL_ACCRUE_INTEREST_FAILED]: 'Set interest rate model accrue interest failed',
    [TokenFailureInfo.SET_INTEREST_RATE_MODEL_FRESH_CHECK]: 'Set interest rate model fresh check',
    [TokenFailureInfo.SET_INTEREST_RATE_MODEL_OWNER_CHECK]: 'Set interest rate model owner check',
    [TokenFailureInfo.SET_MAX_ASSETS_OWNER_CHECK]: 'Set max assets owner check',
    [TokenFailureInfo.SET_ORACLE_MARKET_NOT_LISTED]: 'Set oracle market not listed',
    [TokenFailureInfo.SET_PENDING_ADMIN_OWNER_CHECK]: 'Set pending admin owner check',
    [TokenFailureInfo.SET_RESERVE_FACTOR_ACCRUE_INTEREST_FAILED]: 'Set reserve factor accrue interest failed',
    [TokenFailureInfo.SET_RESERVE_FACTOR_ADMIN_CHECK]: 'Set reserve factor admin check',
    [TokenFailureInfo.SET_RESERVE_FACTOR_FRESH_CHECK]: 'Set reserve factor fresh check',
    [TokenFailureInfo.SET_RESERVE_FACTOR_BOUNDS_CHECK]: 'Set reserve factor bounds check',
    [TokenFailureInfo.TRANSFER_COMPTROLLER_REJECTION]: 'Transfer comptroller rejection',
    [TokenFailureInfo.TRANSFER_NOT_ALLOWED]: 'Transfer not allowed',
    [TokenFailureInfo.TRANSFER_NOT_ENOUGH]: 'Transfer not enough',
    [TokenFailureInfo.TRANSFER_TOO_MUCH]: 'Transfer too much',
    [TokenFailureInfo.ADD_RESERVES_ACCRUE_INTEREST_FAILED]: 'Add reserves accrue interest failed',
    [TokenFailureInfo.ADD_RESERVES_FRESH_CHECK]: 'Add reserves fresh check',
    [TokenFailureInfo.ADD_RESERVES_TRANSFER_IN_NOT_POSSIBLE]: 'Add reserves transfer in not possible',
};
export const parseTokenError = (error, info) => {
    return {
        error: errorMessages[error],
        info: failureMessages[info],
    };
};
