import React from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';
import { ExplorerLink } from '../Shared';
export const TransactionPopup = ({ hash, success, summary, errorMessage, }) => {
    const { chainId } = useWeb3React();
    return (React.createElement(StyledPopupContainer, { success: success },
        React.createElement(StyledPopupTitle, null,
            success ? (React.createElement("i", { className: "fas fa-check-circle" })) : (React.createElement("i", { className: "fas fa-exclamation-circle" })),
            "\u00A0", summary !== null && summary !== void 0 ? summary : 'Hash: ' + hash.substr(0, 8) + '...' + hash.substr(-8)),
        React.createElement("div", null,
            React.createElement(StyledPopupDesc, null, errorMessage ? React.createElement("div", null, errorMessage) : null),
            chainId && (React.createElement(StyledLink, null,
                React.createElement(ExplorerLink, { type: "tx", address: hash }, "View on explorer"))))));
};
const StyledPopupTitle = styled.div `
  padding-bottom: 10px;
`;
const StyledPopupContainer = styled.div `
  padding: 15px;
  border-top: 4px solid;
  border-color: ${(p) => (p.success ? 'var(--color-green)' : 'var(--color-red)')};

  ${StyledPopupTitle} {
    color: ${(p) => (p.success ? 'var(--color-green)' : 'var(--color-red)')};
  }
`;
const StyledPopupDesc = styled.span `
  font-weight: 400;
  font-size: 0.875rem;
`;
const StyledLink = styled.span `
  color: var(--color-magenta);
  display: block;
  font-size: 0.875rem;
`;
