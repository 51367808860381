import { useWeb3React } from '@web3-react/core';
import React, { useMemo } from 'react';
import { config } from '~src/config';
export const ExplorerLink = ({ address, children, type }) => {
    const { chainId } = useWeb3React();
    const url = useMemo(() => {
        return config.network.explorerLink[chainId];
    }, [chainId]);
    return (React.createElement("a", { target: "_blank", href: `${url}/${type || 'address'}/${address}` }, children));
};
