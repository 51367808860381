import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Header } from './components/Header/Header';
import { Provider } from 'react-redux';
import store from './state';
import Updaters from './state/Updaters';
import Modals from './providers/Modals';
import { Popups } from './components/Popups';
import { ContractProvider } from './providers/ContractProvider';
import lazy from '@loadable/component';
import { Footer } from './components/Footer/Footer';
import { LoadingLogo } from './components/Shared/LoadingLogo';
const Home = lazy(() => import('./views/Home'));
const Market = lazy(() => import('./views/Market'));
const MarketDetail = lazy(() => import('./views/MarketDetail'));
const Liquidation = lazy(() => import('./views/Liquidation'));
const Monitor = lazy(() => import('./views/Monitor'));
const getLibrary = (p) => {
    return new Web3Provider(p);
};
export const App = () => {
    return (React.createElement(Providers, null,
        React.createElement(Header, null),
        React.createElement("main", { className: "main-wrapper" },
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", exact: true },
                    React.createElement(Home, { fallback: React.createElement(LoadingLogo, null) })),
                React.createElement(Route, { path: "/markets", exact: true },
                    React.createElement(Market, { fallback: React.createElement(LoadingLogo, null) })),
                React.createElement(Route, { path: "/markets/:asset" },
                    React.createElement(MarketDetail, { fallback: React.createElement(LoadingLogo, null) })),
                React.createElement(Route, { path: "/liquidation", exact: true },
                    React.createElement(Liquidation, { fallback: React.createElement(LoadingLogo, null) })),
                React.createElement(Route, { path: "/monitor", exact: true },
                    React.createElement(Monitor, { fallback: React.createElement(LoadingLogo, null) })),
                React.createElement(Redirect, { to: "/" }))),
        React.createElement(Footer, null)));
};
const Providers = ({ children }) => {
    return (React.createElement(Web3ReactProvider, { getLibrary: getLibrary },
        React.createElement(ContractProvider, null,
            React.createElement(Provider, { store: store },
                React.createElement(Updaters, null),
                React.createElement(Popups, null),
                React.createElement(Modals, null,
                    React.createElement(BrowserRouter, null, children))))));
};
